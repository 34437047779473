import React from 'react';

import { cn } from '../lib/utils.lib';
import { ModelImage } from '../sdk';
import { getMediaUrl } from '../services/media.service';
import { Button } from './Button';

import './ImageListItem.css';

interface P {
  image: ModelImage;
  className?: string;
  onDelete: (image: ModelImage) => void;
  onDragStart: (image: ModelImage) => void;
  onDragEnter: (image: ModelImage) => void;
  onDragEnd: () => void;
  onMouseOver: () => void;
}

export class ImageListItem extends React.Component<P> {
  render() {
    const { className } = this.props;
    const imageClassName = cn('ImageListItem', 'position-relative', className);
    return (
      <div className={imageClassName}>
        {this.renderCard()}
        {this.renderButtons()}
      </div>
    );
  }

  // event handlers

  onDragStart() {
    const { onDragStart, image } = this.props;
    onDragStart(image);
  }

  onDragEnter() {
    const { onDragEnter, image } = this.props;
    onDragEnter(image);
  }

  onDragEnd() {
    const { onDragEnd } = this.props;
    onDragEnd();
  }

  onMouseOver() {
    const { onMouseOver } = this.props;
    onMouseOver();
  }

  onDelete() {
    const { onDelete, image } = this.props;
    onDelete(image);
  }

  // render helpers

  renderCard() {
    return (
      <div
        className="card"
        draggable
        onDragStart={() => this.onDragStart()}
        onDragEnter={() => this.onDragEnter()}
        onDragEnd={() => this.onDragEnd()}
        onMouseOver={() => this.onMouseOver()}
      >
        {this.renderImage()}
      </div>
    );
  }

  renderImage() {
    const { image } = this.props;
    const url = getMediaUrl(image.media_name);
    return <img src={url} alt="" draggable={false} />;
  }

  renderButtons() {
    return (
      <div className="position-absolute ImageListItem_buttons">
        <Button type="danger" icon="trash" onClick={() => this.onDelete()} />
      </div>
    );
  }
}
