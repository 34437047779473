import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { Model, ModelGeneration } from '../../sdk';
import {
  CACHE_MODEL,
  CACHE_MODEL_GENERATION,
  CACHE_MODEL_GENERATIONS,
  loadItemFromCache,
  loadListFromCache,
  saveListToCache,
  saveItemToCache,
} from '../../services/cache.service';
import { formatModelGenerationPeriod } from '../../services/fmt.service';
import { handleError } from '../../services/error.service';
import { goTo } from '../../services/router.service';
import { showModal } from '../../services/modal.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MModelGeneration } from '../modals/MModelGeneration';

interface P {
  match: match<{ brandId: string; modelId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  model?: Model;
  items: ModelGeneration[];
  search: string;
}

export class PModelGenerationsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      model: this.getCachedModel(),
      items: this.getCachedModelGenerations(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PModelGenerations">
        {this.renderBreadcrumb()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const modelId = this.getModelId();
    await showModal<string>(MModelGeneration, { modelId });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: ModelGeneration) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          const modelId = this.getModelId();
          await showModal<string>(MModelGeneration, {
            modelId,
            modelGeneration: item,
          });
          await this.loadData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить поколение "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteModelGeneration(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: ModelGeneration) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { model } = this.state;
    const crumbs = model
      ? [
          { title: 'Каталог', url: '/catalog' },
          { title: model.brand_name, url: `/catalog/${model.brand_id}` },
          { title: model.name },
        ]
      : [];
    return <Breadcrumb items={crumbs} />;
  }

  // other helpers

  getModelId() {
    const { match } = this.props;
    return Number(match.params.modelId);
  }

  getCachedModel() {
    const modelId = this.getModelId();
    const model = loadItemFromCache<Model>(CACHE_MODEL);
    if (!model || model.id !== modelId) {
      return undefined;
    }
    return model;
  }

  getCachedModelGenerations() {
    const modelId = this.getModelId();
    const allItems = loadListFromCache<ModelGeneration>(CACHE_MODEL_GENERATIONS);
    return allItems.filter((item) => item.model_id === modelId);
  }

  getColumns(): Column<ModelGeneration>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
      {
        name: 'Период',
        value: (item) => formatModelGenerationPeriod(item),
        headClassName: 'w-200px',
      },
    ];
  }

  async loadData() {
    const modelId = this.getModelId();
    try {
      const allItems = await sdk.getModelGenerations();
      saveListToCache(CACHE_MODEL_GENERATIONS, allItems);
      const model = await sdk.getModel(modelId);
      const items = allItems.filter((item) => model && item.model_id === model.id);
      this.setState({ items, model });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(modelGeneration: ModelGeneration) {
    saveItemToCache(CACHE_MODEL_GENERATION, modelGeneration);
    goTo(`/catalog/${modelGeneration.brand_id}/${modelGeneration.model_id}/${modelGeneration.id}`);
  }
}

export const PModelGenerations = withRouter(PModelGenerationsBase);
