import Awesomplete from 'awesomplete';
import React from 'react';

import { cn } from '../lib/utils.lib';
import { Form, initAutocomplete } from '../services/form.service';
import { showModal } from '../services/modal.service';
import { MMultiSelect } from './modals/MMultiselect';
import { Option, Select } from './Select';

import './FormGroup.css';

export type AutocompleteOption = {
  id: number;
  value: string;
  label: string;
};

export enum CheckboxValue {
  on = 'on',
  off = 'off',
}

export type FormGroupType =
  | 'text'
  | 'password'
  | 'date'
  | 'time'
  | 'textarea'
  | 'select'
  | 'checks'
  | 'checkbox'
  | 'autocomplete'
  | 'uint'
  | 'multiselect';

interface P {
  type: FormGroupType;
  name: string;
  label?: string;
  form: Form;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  rows?: number;
  monospace?: boolean;
  options?: Option[];
  checkLabel?: string;
  autocompleteOptions?: AutocompleteOption[];
  autocompleteInitialValue?: string | null;
  autocompleteFilter?: (text: string, input: string) => boolean;
  onChange?: (form: Form) => void;
  onSubmit?: () => void;
}

export class FormGroup extends React.Component<P> {
  render() {
    const { name, label, className, required } = this.props;
    const fgClassName = cn(className, 'form-group');
    return (
      <div className={fgClassName} key={name}>
        {label && <label htmlFor={this.getId()}>{required ? label + ' *' : label}</label>}
        {this.renderControl()}
        {this.renderError()}
      </div>
    );
  }

  // event handlers

  onChange(name: string, value: string) {
    const { type, form, onChange } = this.props;
    if (!onChange) {
      return;
    }
    if (type === 'uint' && !value.match(/^\d*$/)) {
      return;
    }
    onChange(form.setValue(name, value).resetError(name));
  }

  async onClickMultiSelect(name: string) {
    const { label, options, form, onChange } = this.props;
    if (!onChange) {
      return;
    }
    const newValue = await showModal<string>(MMultiSelect, {
      title: label,
      options,
      value: form.getValue(name),
    });
    if (newValue === undefined) {
      return;
    }
    onChange(form.setValue(name, newValue).resetError(name));
  }

  onCheckboxGroupChange(name: string, value: string) {
    const { options, form, onChange } = this.props;
    if (!onChange) {
      return;
    }
    const text = form.getValue(name);
    const valueSet = new Set(text ? text.split('\n') : []);
    if (valueSet.has(value)) {
      valueSet.delete(value);
    } else {
      valueSet.add(value);
    }
    const selectedOptions = (options || []).filter((x) => valueSet.has(x.value));
    const newText = selectedOptions.map((x) => x.value).join('\n');
    onChange(form.setValue(name, newText).resetError(name));
  }

  onKeyDown(e: React.KeyboardEvent) {
    const { onSubmit } = this.props;
    if (onSubmit && e.key === 'Enter') {
      onSubmit();
    }
  }

  // render helpers

  renderControl() {
    switch (this.props.type) {
      case 'text':
      case 'password':
      case 'date':
      case 'time':
      case 'uint':
        return this.renderInput();
      case 'textarea':
        return this.renderTextarea();
      case 'select':
        return this.renderSelect();
      case 'autocomplete':
        return this.renderAutocomplete();
      case 'checks':
        return this.renderChecks();
      case 'checkbox':
        return this.renderCheckbox();
      case 'multiselect':
        return this.renderMultiSelect();
      default:
        return null;
    }
  }

  renderError() {
    const { form, name } = this.props;
    return form.hasError(name) && <div className="invalid-feedback">{form.getError(name)}</div>;
  }

  renderInput() {
    const { type, name, form, disabled, placeholder, autoFocus } = this.props;
    const className = cn('form-control', form.hasError(name) ? 'is-invalid' : '');
    return (
      <input
        className={className}
        type={type}
        id={this.getId()}
        value={form.getValue(name)}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete="off"
        onChange={(e) => this.onChange(name, e.target.value)}
        onKeyDown={(e) => this.onKeyDown(e)}
      />
    );
  }

  renderTextarea() {
    const { name, form, disabled, placeholder, rows, monospace } = this.props;
    const className = cn('form-control', form.hasError(name) && 'is-invalid', monospace && 'text-monospace');
    return (
      <textarea
        className={className}
        id={this.getId()}
        value={form.getValue(name)}
        placeholder={placeholder}
        disabled={disabled}
        rows={rows || 5}
        onChange={(e) => this.onChange(name, e.target.value)}
      />
    );
  }

  renderSelect() {
    const { name, form, disabled, options } = this.props;
    const className = cn(form.hasError(name) && 'is-invalid');
    return (
      <Select
        id={this.getId()}
        className={className}
        value={form.getValue(name)}
        disabled={disabled}
        options={options || []}
        onChange={(value) => this.onChange(name, value)}
      />
    );
  }

  renderSelectOptions() {
    return (this.props.options || []).map((option) => (
      <option key={option.value} value={option.value} disabled={option.disabled}>
        {option.title}
      </option>
    ));
  }

  renderAutocomplete() {
    const { type, name, form, disabled, autocompleteOptions, autocompleteFilter } = this.props;
    const className = cn('form-control', form.hasError(name) ? 'is-invalid' : '');
    const ref = (domElement: HTMLInputElement & { awesomplete?: Awesomplete }) => {
      if (autocompleteOptions && domElement && !domElement.awesomplete) {
        domElement.awesomplete = new Awesomplete(domElement, {
          list: autocompleteOptions.map((x) => ({ label: x.label, value: initAutocomplete(x.id) })),
          filter: autocompleteFilter || Awesomplete.FILTER_CONTAINS,
          minChars: 1,
        });
        domElement.addEventListener('awesomplete-selectcomplete', () => {
          this.onChange(name, domElement.value);
        });
      }
    };
    return (
      <input
        className={className}
        type={type}
        id={this.getId()}
        value={this.getAutocompleteInputValue()}
        disabled={disabled}
        autoComplete="off"
        ref={ref}
        onChange={(e) => this.onChange(name, e.target.value)}
        onKeyDown={(e) => this.onKeyDown(e)}
      />
    );
  }

  renderChecks() {
    const { options, name, label, disabled, form } = this.props;
    const className = cn('custom-control custom-checkbox', form.hasError(name) ? 'is-invalid' : '');
    const value = form.getValue(name);
    const valuesSet = new Set(value.split('\n'));
    return (
      <React.Fragment>
        {label && <div className="mb-2">{label}</div>}
        {(options || []).map((option, index) => (
          <div className={className} key={index}>
            <input
              id={`${name}_${option.value}`}
              className="custom-control-input"
              type="checkbox"
              checked={valuesSet.has(option.value)}
              disabled={disabled}
              onChange={() => this.onCheckboxGroupChange(name, option.value)}
            />
            <label className="custom-control-label" htmlFor={`${name}_${option.value}`}>
              {option.title}
            </label>
          </div>
        ))}
      </React.Fragment>
    );
  }

  renderCheckbox() {
    const { name, checkLabel, form, disabled } = this.props;
    const checked = form.getValue(name) === CheckboxValue.on;
    return (
      <div className="form-check" key={name}>
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => this.onChange(name, checked ? CheckboxValue.off : CheckboxValue.on)}
          />
          {checkLabel}
        </label>
      </div>
    );
  }

  renderMultiSelect() {
    const { name, form, disabled, options } = this.props;
    const className = cn('form-control c-pointer', form.hasError(name) ? 'is-invalid' : '');
    const value = form.getValue(name);
    const displayValue = value && value.split('\n').join(', ');
    const emptyValue = options && options.length > 0 ? 'Не выбрано' : '';
    return (
      <input
        className={className}
        type="text"
        id={this.getId()}
        value={displayValue || emptyValue}
        disabled={disabled}
        autoComplete="off"
        readOnly
        onClick={() => this.onClickMultiSelect(name)}
      />
    );
  }

  // other helpers

  private getId() {
    return `fc_${this.props.name}`;
  }

  getAutocompleteInputValue() {
    const { name, form, autocompleteOptions, autocompleteInitialValue } = this.props;
    const value = form.getValue(name);
    if (!value) {
      return value;
    }
    const item = this.findAutocompleteItem(value);
    if (item) {
      return item.value;
    }
    if (!autocompleteOptions && autocompleteInitialValue) {
      return autocompleteInitialValue;
    }
    return value;
  }

  findAutocompleteItem(value: string) {
    const { autocompleteOptions } = this.props;
    if (!value) {
      return undefined;
    }
    if (!autocompleteOptions) {
      return undefined;
    }
    return autocompleteOptions.find((x) => initAutocomplete(x.id) === value);
  }
}
