import React from 'react';

import { Property, PropertyGroup, PropertyType } from '../sdk';
import { Form } from '../services/form.service';
import { FormGroup } from './FormGroup';

interface P {
  propertyGroups: PropertyGroup[];
  properties: Property[];
  form: Form;
  isContentReady: boolean;
  onFormChange(form: Form): void;
}

export class ModelVariantPropertyGroups extends React.Component<P> {
  render() {
    const { propertyGroups, properties, form, isContentReady, onFormChange } = this.props;
    const propertiesByGroupId: { [key: string]: Property[] } = {};
    properties.forEach((property) => {
      if (propertiesByGroupId[property.group_id]) {
        propertiesByGroupId[property.group_id].push(property);
      } else {
        propertiesByGroupId[property.group_id] = [property];
      }
    });
    const propertiesInGroups = propertyGroups.map((group) => ({
      ...group,
      properties: propertiesByGroupId[group.id],
    }));
    return (
      <React.Fragment>
        {propertiesInGroups?.map((group) => (
          <div className="row mb-3" key={group.id}>
            <h5 className="col-12 mb-3">{group.name}</h5>
            {group?.properties?.map((property) => (
              <div className="col-sm-4" key={property.id}>
                <FormGroup
                  type={this.getPropertyInputType(property)}
                  name={`property_${property.id}`}
                  label={property.name}
                  className="ellipsis"
                  disabled={!isContentReady}
                  options={isContentReady ? this.getPropertyOptions(property, form) : []}
                  form={form}
                  onChange={(x) => onFormChange(x)}
                />
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  }

  // other helpers

  getPropertyInputType(property: Property) {
    switch (property.type) {
      case PropertyType.string:
        return 'text';
      case PropertyType.enum:
        return 'select';
      case PropertyType.multi:
        return 'multiselect';
      default:
        return 'text';
    }
  }

  getPropertyOptions(property: Property, form: Form) {
    if (property.type === PropertyType.enum) {
      return this.getPropertyOptionsForEnum(property, form);
    }
    if (property.type === PropertyType.multi) {
      return this.getPropertyOptionsForMulti(property);
    }
    return undefined;
  }

  getPropertyOptionsForEnum(property: Property, form: Form) {
    const options = property.details?.options ?? [];
    const value = form.getValue(`property_${property.id}`);
    const isOptionInList = !value || options.find((option) => option === value);
    return [
      { value: '', title: 'Не выбрано', disabled: true },
      ...options.map((option) => ({
        value: option,
        title: option,
      })),
      ...(!isOptionInList ? [{ value, title: value, disabled: true }] : []),
    ];
  }

  getPropertyOptionsForMulti(property: Property) {
    return (property.details?.options ?? []).map((x) => ({ value: x, title: x }));
  }
}
