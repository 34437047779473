import React from 'react';

import * as sdk from '../../sdk';
import { config } from '../../config';
import { Website } from '../../sdk';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  companyId: number;
  website?: Website;
  close: (website?: Website) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MWebsite extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.website),
    };
  }

  render() {
    return (
      <div className="MWebsite modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { website, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateUrl('sync_url');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const freshWebsite = await (website
        ? sdk.updateWebsite(website.id, this.getUpdateData(form))
        : sdk.createWebsite(this.getCreateData(form)));
      close(freshWebsite);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, website } = this.props;
    const title = website ? website.name : 'Новый сайт';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { website } = this.props;
    const { form } = this.state;
    return (
      <div className="modal-body">
        <FormGroup type="text" name="name" label="Имя" required form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup
          type="text"
          name="sync_url"
          label="Файл синхронизации, URL"
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        {website && (
          <FormGroup
            type="text"
            name="api_key"
            label="Ключ API"
            disabled
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        )}
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(website?: Website) {
    if (!website) {
      return createForm();
    }
    return createForm({
      name: website.name,
      sync_url: website.sync_url,
      api_key: website.api_key,
    });
  }

  getUpdateData(form: Form) {
    const pd = this.getPostData(form);
    return {
      name: pd.name,
      sync_url: pd.sync_url,
    };
  }

  getCreateData(form: Form) {
    const { companyId } = this.props;
    const pd = this.getPostData(form);
    return {
      company_id: companyId,
      name: pd.name,
      sync_url: pd.sync_url,
    };
  }

  getPostData(form: Form) {
    return {
      name: form.getValue('name'),
      sync_url: form.getValue('sync_url'),
    };
  }
}
