import React from 'react';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { Account, AccountRole } from '../../sdk';
import { requireUser } from '../../services/auth.service';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  companyId?: number;
  account?: Account;
  close: (account?: Account) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

const STATUS_ACTIVE = 'active';
const STATUS_INACTIVE = 'inactive';

export class MAccount extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.account),
    };
  }

  render() {
    return (
      <div className="MAccount modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { account, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('email');
    form = form.validateEmail('email');
    form = form.validateRequired('name');
    if (!account) {
      form = form.validateRequired('password');
    }
    const password = form.getValue('password');
    if (password && password.length < 8) {
      form = form.setError('password', 'Пароль должен содержать не менее 8 символов');
    }
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const freshAccount = await (account
        ? sdk.updateAccount(account.id, pd)
        : sdk.createAccount({ ...pd, password: pd.password! }));
      close(freshAccount);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, account } = this.props;
    const title = account ? account.name : 'Новый пользователь';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    const { account } = this.props;
    return (
      <div className="modal-body">
        <FormGroup type="text" name="name" label="Имя" required form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup type="text" name="email" label="Email" required form={form} onChange={(x) => this.onFormChange(x)} />
        {account && (
          <FormGroup
            type="select"
            name="status"
            label="Статус"
            form={form}
            options={this.getStatusOptions()}
            onChange={(x) => this.onFormChange(x)}
          />
        )}

        <FormGroup
          type="password"
          name="password"
          label={account ? 'Изменить пароль' : 'Пароль'}
          required={!account}
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(account?: Account) {
    if (!account) {
      return createForm({
        status: STATUS_ACTIVE,
      });
    }
    return createForm({
      email: account.email,
      name: account.name,
      status: account.is_active ? STATUS_ACTIVE : STATUS_INACTIVE,
    });
  }

  getStatusOptions() {
    return [
      { value: STATUS_ACTIVE, title: 'Активный' },
      { value: STATUS_INACTIVE, title: 'Неактивный' },
    ];
  }

  getPostData(form: Form) {
    const { companyId } = this.props;
    const user = requireUser();
    return {
      company_id: companyId || user.company_id || undefined,
      email: form.getValue('email'),
      password: form.getValue('password') || undefined,
      role: companyId || user.company_id ? AccountRole.company_admin : AccountRole.system_admin,
      name: form.getValue('name'),
      is_active: form.getValue('status') === STATUS_ACTIVE,
    };
  }
}
