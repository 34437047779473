import * as api from '../services/api.service';
import { Account, AccountRole } from './types.sdk';

interface AccountQuery {
  role?: AccountRole;
  company_id?: number;
}

export async function getAccounts(query: AccountQuery = {}) {
  const result = await api.get('/account', query);
  return result.accounts as Account[];
}

interface CreateAccountData {
  company_id?: number;
  email: string;
  password: string;
  role: AccountRole;
  name: string;
}

export async function createAccount(data: CreateAccountData) {
  const result = await api.post('/account', data);
  return result.account as Account;
}

interface UpdateAccountData {
  email?: string;
  password?: string;
  name?: string;
  ia_active?: boolean;
}

export async function updateAccount(id: number, data: UpdateAccountData) {
  const result = await api.patch(`/account/${id}`, data);
  return result.account as Account;
}

export async function deleteAccount(id: number) {
  await api.del(`/account/${id}`);
}
