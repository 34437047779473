import { config } from '../config';
import { getLsItem, setLsItem } from './ls.service';

export const CACHE_PREFIX = 'cache_';

export const CACHE_CARS = 'cars';
export const CACHE_COMPANIES = 'companies';
export const CACHE_MODELS = 'models';
export const CACHE_MODEL_GENERATIONS = 'model_generations';
export const CACHE_MODEL_STYLES = 'model_styles';
export const CACHE_MODEL_VARIANTS = 'model_variants';
export const CACHE_BRANDS = 'brands';
export const CACHE_OPTION_GROUPS = 'option_groups';
export const CACHE_OPTIONS = 'options';
export const CACHE_PROPERTIES = 'properties';
export const CACHE_PROPERTY_GROUPS = 'property_groups';
export const CACHE_SYNC_REPORTS = 'sync_reports';
export const CACHE_TICKET = 'ticket';
export const CACHE_TICKETS = 'tickets';
export const CACHE_USERS = 'users';
export const CACHE_WEBSITES = 'websites';

export const CACHE_BRAND = 'brand';
export const CACHE_COMPANY = 'company';
export const CACHE_MODEL = 'model';
export const CACHE_MODEL_GENERATION = 'model_generation';
export const CACHE_MODEL_VARIANT = 'model_variant';
export const CACHE_MODEL_STYLE = 'model_style';
export const CACHE_OPTION = 'option';
export const CACHE_OPTION_GROUP = 'option_group';
export const CACHE_PROPERTY = 'property';
export const CACHE_PROPERTY_GROUP = 'property_group';
export const CACHE_WEBSITE = 'website';

export function loadListFromCache<T>(key: string) {
  return getLsItem<T[]>(CACHE_PREFIX + key) || [];
}

export function saveListToCache<T>(key: string, list: T[]) {
  setLsItem(CACHE_PREFIX + key, list.slice(0, config.itemsPerPage));
}

export function loadItemFromCache<T>(key: string) {
  return getLsItem<T>(CACHE_PREFIX + key);
}

export function saveItemToCache<T>(key: string, item: T) {
  setLsItem(CACHE_PREFIX + key, item);
}
