import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import * as sdk from '../../sdk';
import { OptionGroup, Option } from '../../sdk';
import {
  CACHE_OPTIONS,
  CACHE_OPTION_GROUP,
  loadItemFromCache,
  loadListFromCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MPrompt } from '../modals/MPrompt';

interface P {
  match: match<{ groupId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  optionGroup?: OptionGroup;
  items: Option[];
  search: string;
}

export class POptionsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      optionGroup: this.getCachedOptionGroup(),
      items: this.getCachedOptions(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="POptions">
        {this.renderBreadcrumb()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          buttons={this.getMoveButtons()}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const optionGroupId = this.getOptionGroupId();
    const name = await showModal<string>(MPrompt, {
      label: 'Имя',
      title: 'Новая опция',
      action: 'Сохранить',
      isRequired: true,
    });
    if (name) {
      try {
        await sdk.createOption({
          name,
          group_id: optionGroupId,
        });
        await this.loadData();
      } catch (err) {
        handleError(err);
      }
    }
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: Option) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await this.runPrimaryAction(item);
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить опцию "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteOption(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: Option) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { optionGroup } = this.state;
    const crumbs = optionGroup ? [{ title: 'Опции', url: '/options' }, { title: optionGroup.name }] : [];
    return <Breadcrumb items={crumbs} />;
  }

  // other helpers

  getOptionGroupId() {
    const { match } = this.props;
    return Number(match.params.groupId);
  }

  getCachedOptionGroup() {
    const optionGroupId = this.getOptionGroupId();
    const optionGroup = loadItemFromCache<OptionGroup>(CACHE_OPTION_GROUP);
    if (!optionGroup || optionGroup.id !== optionGroupId) {
      return undefined;
    }
    return optionGroup;
  }

  getCachedOptions() {
    const optionGroupId = this.getOptionGroupId();
    const allItems = loadListFromCache<Option>(CACHE_OPTIONS);
    return allItems.filter((item) => optionGroupId && item.group_id === optionGroupId);
  }

  getColumns(): Column<Option>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
    ];
  }

  async loadData() {
    const optionGroupId = this.getOptionGroupId();
    try {
      const allItems = await sdk.getOptions();
      saveListToCache(CACHE_OPTIONS, allItems);
      const optionGroup = optionGroupId ? await sdk.getOptionGroup(optionGroupId) : undefined;
      const items = allItems.filter((item) => optionGroup && item.group_id === optionGroup.id);
      this.setState({ items, optionGroup });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(option: Option) {
    const name = await showModal<string>(MPrompt, {
      label: 'Имя',
      title: option.name,
      action: 'Сохранить',
      defaultValue: option.name,
      isRequired: true,
    });
    if (name) {
      try {
        await sdk.updateOption(option.id, { name });
        await this.loadData();
      } catch (err) {
        handleError(err);
      }
    }
  }

  getMoveButtons() {
    return [
      {
        icon: 'arrow-up' as IconName,
        action: async (option: Option) => {
          try {
            await sdk.moveOptionUp(option.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down' as IconName,
        action: async (option: Option) => {
          try {
            await sdk.moveOptionDown(option.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }
}

export const POptions = withRouter(POptionsBase);
