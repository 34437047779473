import React from 'react';

import { cn } from '../lib/utils.lib';

interface P {
  className?: string;
  label: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export class AttachmentFile extends React.Component<P> {
  render() {
    const { className, disabled, label, onChange } = this.props;
    const attachClassName = cn(
      className,
      'align-items-center d-flex btn btn-sm btn-outline-secondary',
      disabled && 'disabled',
    );
    return (
      <label className={attachClassName}>
        {label}
        <input disabled={disabled} type="file" hidden multiple onChange={(e) => onChange(e)} />
      </label>
    );
  }
}
