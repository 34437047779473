import * as sdk from '../sdk';
import { Account } from '../sdk';
import { setToken } from './api.service';
import { clearLs, getLsItem, LSK_START_URL, LSK_TOKEN, LSK_USER, setLsItem } from './ls.service';
import { goTo } from './router.service';

let user: Account | undefined;

export function initFromLs() {
  const token = getLsItem<string>(LSK_TOKEN);
  const u = getLsItem<Account>(LSK_USER);
  if (token && u) {
    setToken(token);
    user = u;
  }
}

export function getUser() {
  return user;
}

export function requireUser() {
  if (!user) {
    throw new Error('Unauthorized');
  }
  return user;
}

export function isSystemUser() {
  return Boolean(user && !user.company_id);
}

export function isCompanyUser() {
  return Boolean(user && user.company_id);
}

export async function login(name: string, password: string) {
  const token = await sdk.login(name, password);
  setToken(token);
  user = await sdk.getUser();
  setLsItem(LSK_TOKEN, token);
  setLsItem(LSK_USER, user);
  const startUrl = getLsItem<string>(LSK_START_URL);
  setLsItem(LSK_START_URL, undefined);
  goTo(startUrl || '/');
}

export function logout() {
  clearAuth();
  goTo('/login');
}

export function runUnauthorizedRedirect() {
  clearAuth();
  setLsItem(LSK_START_URL, window.location.pathname + window.location.search);
  goTo('/login');
}

// private

function clearAuth() {
  setToken(undefined);
  user = undefined;
  clearLs();
}
