import React from 'react';

import * as sdk from '../../sdk';
import { config } from '../../config';
import { ModelStyle } from '../../sdk';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  modelGenerationId: number;
  modelStyle?: ModelStyle;
  close: (modelStyle?: ModelStyle) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MModelStyle extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.modelStyle),
    };
  }

  render() {
    return (
      <div className="MModelStyle modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(newForm: Form) {
    this.setState({ form: newForm });
  }

  async onSave() {
    const { modelStyle, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const freshModelStyle = await (modelStyle ? sdk.updateModelStyle(modelStyle.id, pd) : sdk.createModelStyle(pd));
      close(freshModelStyle);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, modelStyle } = this.props;
    const title = modelStyle ? modelStyle.name : 'Новый кузов';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    return (
      <div className="modal-body">
        <FormGroup type="text" name="name" label="Имя" required form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup
          type="textarea"
          name="variants"
          label="Названия комплектаций"
          rows={7}
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(modelStyle?: ModelStyle) {
    if (!modelStyle) {
      return createForm({});
    }
    return createForm({
      name: modelStyle.name,
      variants: this.getVariantsByObject(modelStyle.variants),
    });
  }

  getPostData(form: Form) {
    const { modelGenerationId } = this.props;
    return {
      name: form.getValue('name'),
      model_generation_id: modelGenerationId,
      variants: this.getVariantsByForm(form.getValue('variants')),
    };
  }

  getVariantsByObject(variants?: string[]) {
    return variants ? variants.join('\n') : '';
  }

  getVariantsByForm(value: string) {
    return value
      .split('\n')
      .map((str) => str.trim())
      .filter((str) => str);
  }
}
