import * as api from '../services/api.service';
import { Option } from './types.sdk';

export async function getOptions() {
  const result = await api.get('/option');
  return result.options as Option[];
}

export async function getOption(id: number) {
  const result = await api.get(`/option/${id}`);
  return result.option as Option;
}

interface CreateOptionData {
  name: string;
  group_id: number;
}

export async function createOption(data: CreateOptionData) {
  const result = await api.post('/option', data);
  return result.option as Option;
}

interface UpdateOptionData {
  name?: string;
  group_id?: number;
}

export async function updateOption(id: number, data: UpdateOptionData) {
  const result = await api.patch(`/option/${id}`, data);
  return result.option as Option;
}

export async function moveOptionUp(id: number) {
  await api.post(`/option/${id}/up`);
}

export async function moveOptionDown(id: number) {
  await api.post(`/option/${id}/down`);
}

export async function deleteOption(id: number) {
  await api.del(`/option/${id}`);
}
