import React from 'react';

import { cn } from '../lib/utils.lib';
import { AttachmentFile } from './AttachmentFile';
import { AttachmentItem } from './AttachmentItem';

interface P {
  className?: string;
  disabled?: boolean;
  onChange: (files: File[]) => void;
  files: File[];
}

export class AttachmentArea extends React.Component<P> {
  render() {
    const { className, files, disabled } = this.props;
    return (
      <div className={cn(className, 'd-flex flex-wrap')}>
        <AttachmentFile
          className="mr-1 mb-1"
          label="Добавить файл"
          disabled={disabled}
          onChange={(e) => this.onAttachment(e)}
        />
        {files.map((x) => (
          <AttachmentItem className="mr-1 mb-1" file={x} key={x.name} onRemove={() => this.onDeleteFile(x.name)} />
        ))}
      </div>
    );
  }

  // event handlers

  async onAttachment(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = this.props;
    const { onChange } = this.props;
    const attachments = e.target.files;
    if (!attachments) {
      return;
    }
    const fileList = Array.from(attachments);
    const freshFiles = [...files];
    for (const file of fileList) {
      if (!files.find((x) => x.name === file.name)) {
        freshFiles.push(file);
      }
    }
    onChange(freshFiles);
  }

  onDeleteFile(fileName: string) {
    const { onChange, files } = this.props;
    const freshFiles = [...files].filter((x) => x.name !== fileName);
    this.setState({ files: freshFiles });
    onChange(freshFiles);
  }
}
