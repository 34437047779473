import React from 'react';

import { Option, OptionGroup } from '../sdk';
import { Form } from '../services/form.service';
import { FormGroup } from './FormGroup';

interface P {
  optionGroups: OptionGroup[];
  options: Option[];
  form: Form;
  isContentReady: boolean;
  onFormChange(form: Form): void;
}

export class ModelVariantOptionGroups extends React.Component<P> {
  render() {
    const { optionGroups, options, form, isContentReady, onFormChange } = this.props;
    const optionsByGroupId: { [key: string]: Option[] } = {};
    options.forEach((option) => {
      if (optionsByGroupId[option.group_id]) {
        optionsByGroupId[option.group_id].push(option);
      } else {
        optionsByGroupId[option.group_id] = [option];
      }
    });
    const optionsInGroups = optionGroups.map((group) => ({
      ...group,
      options: optionsByGroupId[group.id],
    }));
    return (
      <React.Fragment>
        {optionsInGroups?.map((group) => (
          <div className="row mb-3" key={group.id}>
            <h5 className="col-12 mb-3">{group.name}</h5>
            {group?.options?.map((option) => (
              <div className="col-sm-4" key={option.id}>
                <FormGroup
                  type="checkbox"
                  name={`option_${option.id}`}
                  checkLabel={option.name}
                  className="ellipsis"
                  disabled={!isContentReady}
                  form={form}
                  onChange={(x) => onFormChange(x)}
                />
              </div>
            ))}
          </div>
        ))}
      </React.Fragment>
    );
  }
}
