export interface Account {
  id: number;
  created_at: string;
  updated_at: string;
  email: string;
  role: AccountRole;
  name: string;
  is_active: boolean;
  company_id: number | null;
}

export enum AccountRole {
  system_admin = 'system_admin',
  company_admin = 'company_admin',
}

export interface Media {
  id: number;
  name: string;
  hash: string;
}

export interface Company {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  email: string;
}

export interface PropertyGroup {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  position: number;
}

export enum PropertyType {
  string = 'string',
  enum = 'enum',
  multi = 'multi',
}

export interface PropertyDetails {
  options?: string[];
}

export interface Property {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  position: number;
  group_id: number;
  type: PropertyType;
  details?: PropertyDetails;
}

export interface OptionGroup {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  position: number;
}

export interface Option {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  position: number;
  group_id: number;
}

export interface Brand {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface Model {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  brand_id: number;
  brand_name: string;
}

export interface ModelGeneration {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  year_from: number;
  year_to?: number;
  model_id: number;
  model_name: string;
  brand_id: number;
  brand_name: string;
}

export interface ModelStyle {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  variants: string[];
  model_generation_id: number;
  model_generation_name: string;
  model_id: number;
  model_name: string;
  brand_id: number;
  brand_name: string;
}

export enum EngineType {
  petrol = 'petrol',
  diesel = 'diesel',
  hybrid = 'hybrid',
}

export enum TransmissionType {
  mt = 'mt',
  at = 'at',
  cvt = 'cvt',
}

export enum Drive {
  rwd = 'rwd',
  fwd = 'fwd',
  xwd = '4wd',
}

export interface ModelVariant {
  id: number;
  name: string;
  code: string | null;
  price: number | null;
  engine_type: EngineType | null;
  transmission_type: TransmissionType | null;
  drive: Drive | null;
  motor_volume: number | null;
  motor_power: number | null;
  created_at: string;
  updated_at: string;
  model_style_id: number;
  model_style_name: string;
  model_generation_id: number;
  model_generation_name: string;
  model_id: number;
  model_name: string;
  brand_id: number;
  brand_name: string;
  options?: ModelVariantOption[];
  properties?: ModelVariantProperty[];
  colors?: ModelColor[];
  images?: ModelImage[];
}

export interface ModelVariantProperty {
  id: number;
  name: string;
  value: string;
  created_at: string;
  updated_at: string;
  group_id: number;
  group_name: string;
}

export interface ModelVariantOption {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  group_id: number;
  group_name: string;
}

export interface ModelColor {
  id: number;
  name: string;
  code: string;
  created_at: string;
  updated_at: string;
  model_variant_id: number;
  model_variant_name: string;
  model_style_id: number;
  model_style_name: number;
  model_generation_id: number;
  model_generation_name: string;
  model_id: number;
  model_name: string;
  brand_id: number;
  brand_name: string;
}

export enum CarSource {
  auto = 'auto',
  manual = 'manual',
}

export interface Car {
  id: number;
  vin: string;
  year: number;
  price: number;
  final_price: number;
  source: CarSource;
  is_hidden: boolean;
  website_id: number;
  company_id: number;
  created_at: string;
  updated_at: string;
  model_color_id: number;
  model_color_name: string;
  model_color_code: string;
  model_variant_id: number;
  model_variant_name: string;
  model_variant_code: string;
  model_style_id: number;
  model_style_name: string;
  model_generation_id: number;
  model_generation_name: string;
  model_id: number;
  model_name: string;
  brand_id: number;
  brand_name: string;
}

export interface ModelImage {
  id: number;
  created_at: string;
  model_color_id: number;
  type: string;
  position: number;
  media_id: number;
  media_name: string;
  media_size: number;
}

export interface TicketComment {
  id: number;
  ticket_id: number;
  body: string;
  created_at: string;
  author_id: number;
  author_name: string;
  author_role: AccountRole;
}

export interface TicketCommentMedia {
  ticket_comment_id: number;
  media_id: number;
  media_name: string;
  original_file_name: string;
}

export interface Ticket {
  id: number;
  title: string;
  started_at: string;
  closed_at: string;
  created_at: string;
  updated_at: string;
  last_comment_at: string;
  creator_id: string;
  creator_name: string;
  company_id: number;
  company_name: string;
  comments: TicketComment[];
  attachments: TicketCommentMedia[];
}

export interface SyncReport {
  id: number;
  created_at: string;
  error: string;
  items_total: number;
  items_valid: number;
  items_ignored: number;
  items?: SyncReportItem[];
}

export interface SyncReportItem {
  id: number;
  position: number;
  error: string;
  vin: string;
  diff: any;
  is_ignored: boolean;
  is_new: boolean;
}

export interface Website {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  api_key: string;
  sync_url: string;
  company_id: number;
  company_name: string;
}
