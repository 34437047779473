import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import { cn } from '../../lib/utils.lib';
import * as sdk from '../../sdk';
import { AccountRole, Company, Ticket } from '../../sdk';
import { requireUser } from '../../services/auth.service';
import {
  CACHE_COMPANIES,
  CACHE_COMPANY,
  CACHE_TICKET,
  CACHE_TICKETS,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { formatDate, formatTicketStatus } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import { CompanyTab, getCompanyCrumbs, getCompanyTabs } from '../../services/navigation.service';
import { goTo } from '../../services/router.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MTicket } from '../modals/MTicket';
import { Select } from '../Select';
import { Tabs } from '../Tabs';

enum StatusEnum {
  opened = 'opened',
  closed = 'closed',
}

interface P {
  match: match<{ companyId?: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  company?: Company;
  companies: Company[];
  items: Ticket[];
  search: string;
  status: string;
  filterCompanyId: string;
}

export class PTicketsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      company: this.getCachedCompany(),
      companies: loadListFromCache(CACHE_COMPANIES),
      items: this.getCachedTickets(),
      search: '',
      status: StatusEnum.opened,
      filterCompanyId: '',
    };
  }

  render() {
    const { search } = this.state;
    const user = requireUser();
    const isAdmin = user.role === AccountRole.system_admin;
    const isAdminPage = this.isAdminPage();
    return (
      <div className="PTickets">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        <div className="d-flex mb-3">
          {!isAdminPage && <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />}
          <input
            className={cn('form-control', !isAdminPage && 'ml-3')}
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
          {this.renderFilter()}
        </div>
        <List
          items={this.getItems()}
          columns={this.getColumns()}
          search={search}
          onItemSelect={(x) => this.onItemSelect(x)}
          onGetItemMenu={isAdmin ? (x) => this.onGetItemMenu(x) : undefined}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  // event handlers

  onFilterStatusChange(value: string) {
    this.setState({ status: value });
  }

  onFilterCompanyChange(value: string) {
    this.setState({ filterCompanyId: value });
  }

  async onAdd() {
    const companyId = this.getCompanyId();
    await showModal(MTicket, { companyId });
    await this.refreshData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: Ticket) {
    const items = [];
    item.closed_at
      ? items.push({
          name: 'Переоткрыть',
          action: async () => {
            const msg = `Переоткрыть обращение "${item.id}"?`;
            if (!window.confirm(msg)) {
              return;
            }
            try {
              await sdk.reopenTicket(item.id);
              await this.refreshData();
            } catch (err) {
              handleError(err);
            }
          },
        })
      : items.push({
          name: 'Закрыть',
          action: async () => {
            const msg = `Закрыть обращение "${item.id}"?`;
            if (!window.confirm(msg)) {
              return;
            }
            try {
              await sdk.closeTicket(item.id);
              await this.refreshData();
            } catch (err) {
              handleError(err);
            }
          },
        });
    items.push({
      name: 'Удалить',
      action: async () => {
        const msg = `Удалить обращение "${item.id}"?`;
        if (!window.confirm(msg)) {
          return;
        }
        try {
          await sdk.deleteTicket(item.id);
          await this.refreshData();
        } catch (err) {
          handleError(err);
        }
      },
    });
    return items;
  }

  async onItemSelect(item: Ticket) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderFilter() {
    return (
      <React.Fragment>
        <Select
          options={this.getFilterStatusOptions()}
          className="ml-3"
          value={this.state.status}
          onChange={(value) => this.onFilterStatusChange(value)}
        />
        {this.isAdminPage() && (
          <Select
            options={this.getFilterCompanyOptions()}
            className="ml-3"
            value={this.state.filterCompanyId}
            onChange={(value) => this.onFilterCompanyChange(value)}
          />
        )}
      </React.Fragment>
    );
  }

  renderBreadcrumb() {
    const { company } = this.state;
    if (!this.getCompanyId()) {
      return null;
    }
    return <Breadcrumb items={getCompanyCrumbs(company)} />;
  }

  renderTabs() {
    const companyId = this.getCompanyId();
    if (!companyId) {
      return null;
    }
    return <Tabs items={getCompanyTabs(companyId, CompanyTab.tickets)} />;
  }

  // other helpers

  getCompanyId() {
    const { match } = this.props;
    return Number(match.params.companyId) || undefined;
  }

  getCachedCompany() {
    const companyId = this.getCompanyId();
    if (!companyId) {
      return undefined;
    }
    const company = loadItemFromCache<Company>(CACHE_COMPANY);
    if (!company) {
      return undefined;
    }
    if (company.id !== companyId) {
      return undefined;
    }
    return company;
  }

  getCachedTickets() {
    return this.getCompanyId() ? [] : loadListFromCache<Ticket>(CACHE_TICKETS);
  }

  getColumns() {
    const { status } = this.state;
    const columns: Column<Ticket>[] = [
      {
        name: '#',
        value: (item: Ticket) => item.id,
        headClassName: 'w-80px',
      },
    ];
    if (this.isAdminPage()) {
      columns.push({
        name: 'Клиент',
        value: (item) => item.company_name,
        headClassName: 'w-200px',
        ellipsis: true,
      });
    }
    columns.push({
      name: 'Тема',
      value: (item: Ticket) => item.title,
      ellipsis: true,
    });
    if (status === StatusEnum.opened) {
      columns.push({
        name: 'Статус',
        value: (item) => formatTicketStatus(item),
        headClassName: 'w-120px',
      });
      columns.push({
        name: 'Создано',
        value: (item) => formatDate(item.created_at),
        headClassName: 'w-120px',
      });
      columns.push({
        name: 'Сообщение',
        value: (item) => formatDate(item.last_comment_at),
        headClassName: 'w-120px',
      });
    } else {
      columns.push({
        name: 'Создано',
        value: (item) => formatDate(item.created_at),
        headClassName: 'w-120px',
      });
      columns.push({
        name: 'Закрыто',
        value: (item) => formatDate(item.closed_at),
        headClassName: 'w-120px',
      });
    }
    return columns;
  }

  async refreshData() {
    const companyId = this.getCompanyId();
    try {
      let companies: Company[] = [];
      const items = await sdk.getTickets({
        company_id: companyId,
      });
      if (this.isAdminPage()) {
        companies = await sdk.getCompanies();
      }
      const company = companyId ? await sdk.getCompany(companyId) : undefined;
      this.setState({ items, company, companies });
      if (!companyId) {
        saveListToCache(CACHE_TICKETS, items);
      }
      saveItemToCache(CACHE_COMPANY, company);
    } catch (e) {
      handleError(e);
    }
  }

  getItems(): Ticket[] {
    const { items, status, filterCompanyId } = this.state;
    const condition = (item: Ticket) => {
      return (
        !item.closed_at === (status === StatusEnum.opened) &&
        (!filterCompanyId || item.company_id === Number(filterCompanyId))
      );
    };
    return items.filter(condition);
  }

  getFilterStatusOptions() {
    return [
      {
        value: StatusEnum.opened,
        title: 'Открытые',
      },
      {
        value: StatusEnum.closed,
        title: 'Закрытые',
      },
    ];
  }

  getFilterCompanyOptions() {
    const { companies } = this.state;
    return [
      {
        title: 'Все клиенты',
        value: '',
      },
      ...companies.map((c) => ({
        value: String(c.id),
        title: c.name,
      })),
    ];
  }

  async runPrimaryAction(ticket: Ticket) {
    const companyId = this.getCompanyId();
    saveItemToCache(CACHE_TICKET, ticket);
    if (companyId) {
      goTo(`/companies/${companyId}/tickets/${ticket.id}`);
    } else {
      goTo(`/tickets/${ticket.id}`);
    }
  }

  isAdminPage() {
    const user = requireUser();
    return user.role === AccountRole.system_admin && !this.getCompanyId();
  }
}

export const PTickets = withRouter(PTicketsBase);
