import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { cn } from '../../lib/utils.lib';
import { Company, Website } from '../../sdk';
import { requireUser } from '../../services/auth.service';
import {
  CACHE_COMPANY,
  CACHE_WEBSITE,
  CACHE_WEBSITES,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { CompanyTab, getCompanyCrumbs, getCompanyTabs } from '../../services/navigation.service';
import { goTo } from '../../services/router.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MWebsite } from '../modals/MWebsite';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ companyId?: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  items: Website[];
  company?: Company;
  search: string;
}

class PWebsitesBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      items: this.getCachedWebsites(),
      company: this.getCachedCompany(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PWebsites">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        <div className="d-flex mb-3">
          {this.isSystemAdmin() && <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />}
          <input
            className={cn('form-control', this.isSystemAdmin() && 'ml-3')}
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const companyId = this.getCompanyId();
    const user = requireUser();
    await showModal(MWebsite, { companyId: companyId || user.company_id });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: Website) {
    const companyId = this.getCompanyId();
    const user = requireUser();
    let items = [
      {
        name: 'Открыть',
        action: async () => {
          await this.runPrimaryAction(item);
        },
      },
    ];
    if (this.isSystemAdmin()) {
      items = items.concat([
        {
          name: 'Редактировать',
          action: async () => {
            await showModal(MWebsite, { website: item, companyId: companyId || user.company_id });
            await this.loadData();
          },
        },
        {
          name: 'Удалить',
          action: async () => {
            const msg = `Удалить сайт "${item.name}"?`;
            if (!window.confirm(msg)) {
              return;
            }
            try {
              await sdk.deleteWebsite(item.id);
              await this.loadData();
            } catch (err) {
              handleError(err);
            }
          },
        },
      ]);
    }
    return items;
  }

  async onItemSelect(item: Website) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderTabs() {
    const companyId = this.getCompanyId();
    if (!companyId) {
      return null;
    }
    return <Tabs items={getCompanyTabs(companyId, CompanyTab.websites)} />;
  }

  renderBreadcrumb() {
    const { company } = this.state;
    if (!this.getCompanyId()) {
      return null;
    }
    return <Breadcrumb items={getCompanyCrumbs(company)} />;
  }

  // other helpers

  getCompanyId() {
    const { match } = this.props;
    return Number(match.params.companyId) || undefined;
  }

  getColumns(): Column<Website>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
    ];
  }

  getCachedWebsites() {
    return this.getCompanyId() ? [] : loadListFromCache<Website>(CACHE_WEBSITES);
  }

  getCachedCompany() {
    const companyId = this.getCompanyId();
    const company = loadItemFromCache<Company>(CACHE_COMPANY);
    if (!company) {
      return undefined;
    }
    if (company.id !== companyId) {
      return undefined;
    }
    return company;
  }

  async loadData() {
    try {
      const companyId = this.getCompanyId();
      const company = companyId ? await sdk.getCompany(companyId) : undefined;
      const items = await sdk.getWebsites({
        company_id: companyId,
      });
      this.setState({ items, company });
      if (!companyId) {
        saveListToCache(CACHE_WEBSITES, items);
      }
      saveItemToCache(CACHE_COMPANY, company);
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(website: Website) {
    const companyId = this.getCompanyId();
    saveItemToCache(CACHE_WEBSITE, website);
    companyId ? goTo(`/companies/${companyId}/websites/${website.id}/cars`) : goTo(`/websites/${website.id}/cars`);
  }

  isSystemAdmin() {
    const user = requireUser();
    return user && !user.company_id;
  }
}

export const PWebsites = withRouter(PWebsitesBase);
