import * as api from '../services/api.service';
import { Property, PropertyType, PropertyDetails } from './types.sdk';

export async function getProperties() {
  const result = await api.get('/property');
  return result.properties as Property[];
}

export async function getProperty(id: number) {
  const result = await api.get(`/property/${id}`);
  return result.property as Property;
}

interface CreatePropertyData {
  name: string;
  type: PropertyType;
  group_id: number;
  details?: PropertyDetails;
}

export async function createProperty(data: CreatePropertyData) {
  const result = await api.post('/property', data);
  return result.property as Property;
}

interface UpdatePropertyData {
  name?: string;
  type?: PropertyType;
  group_id?: number;
  details?: PropertyDetails;
}

export async function updateProperty(id: number, data: UpdatePropertyData) {
  const result = await api.patch(`/property/${id}`, data);
  return result.property as Property;
}

export async function movePropertyUp(id: number) {
  await api.post(`/property/${id}/up`);
}

export async function movePropertyDown(id: number) {
  await api.post(`/property/${id}/down`);
}

export async function deleteProperty(id: number) {
  await api.del(`/property/${id}`);
}
