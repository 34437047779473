import React from 'react';

import * as sdk from '../sdk';
import { createForm, Form } from '../services/form.service';
import { Ticket } from '../sdk';
import { AttachmentArea } from './AttachmentArea';
import { Button } from './Button';
import { FormGroup } from './FormGroup';
import { handleError } from '../services/error.service';

interface P {
  onUpdate: () => Promise<void>;
  ticket: Ticket;
}

interface S {
  files: File[];
  isCommentSending: boolean;
  form: Form;
}

export class TicketCommentForm extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      files: [],
      isCommentSending: false,
      form: createForm(),
    };
  }
  render() {
    const { files, form, isCommentSending } = this.state;
    const isEmpty = !Boolean(form.getValue('body').trim());
    return (
      <div className="pb-5">
        <FormGroup
          placeholder="Ваш комментарий"
          type="textarea"
          name="body"
          required
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <div className="d-flex justify-content-between">
          <AttachmentArea
            className="w-100"
            files={files}
            disabled={isCommentSending}
            onChange={(files) => this.onChangeAttachments(files)}
          />
          <div className="ml-2">
            <Button
              type="success"
              text="Отправить"
              disabled={isCommentSending || isEmpty}
              onClick={() => this.onAddComment()}
            />
          </div>
        </div>
      </div>
    );
  }

  // event handlers

  onChangeAttachments(files: File[]) {
    this.setState({ files });
  }

  onFormChange(form: Form) {
    this.setState({ form });
  }

  // other helpers

  async onAddComment() {
    const { files } = this.state;
    const { ticket, onUpdate } = this.props;
    let { form } = this.state;
    if (!ticket) {
      return;
    }
    form = form.trimValues();
    this.setState({ isCommentSending: true });
    const attachments = [];
    try {
      for (const file of files) {
        const media = await sdk.uploadMedia(file);
        attachments.push(media);
      }
      await sdk.createTicketComment({
        attachments,
        ticket_id: ticket.id,
        body: form.getValue('body'),
      });
      form = form.setValue('body', '');
      this.setState({
        form,
        isCommentSending: true,
        files: [],
      });
      await onUpdate();
      this.setState({ isCommentSending: false });
    } catch (err) {
      this.setState({ isCommentSending: false });
      handleError(err);
    }
  }
}
