import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import * as sdk from '../../sdk';
import { PropertyGroup, Property } from '../../sdk';
import {
  CACHE_PROPERTIES,
  CACHE_PROPERTY_GROUP,
  loadItemFromCache,
  loadListFromCache,
  saveListToCache,
} from '../../services/cache.service';
import { formatPropertyType } from '../../services/fmt.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MProperty } from '../modals/MProperty';

interface P {
  match: match<{ groupId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  propertyGroup?: PropertyGroup;
  items: Property[];
  search: string;
}

export class PPropertiesBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      propertyGroup: this.getCachedPropertyGroup(),
      items: this.getCachedProperties(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PProperties">
        {this.renderBreadcrumb()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          buttons={this.getMoveButtons()}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const propertyGroupId = this.getPropertyGroupId();
    await showModal(MProperty, { propertyGroupId });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: Property) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await this.runPrimaryAction(item);
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить свойство "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteProperty(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: Property) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { propertyGroup } = this.state;
    const crumbs = propertyGroup
      ? [{ title: 'Характеристики', url: '/properties' }, { title: propertyGroup.name }]
      : [];
    return <Breadcrumb items={crumbs} />;
  }

  // other helpers

  getPropertyGroupId() {
    const { match } = this.props;
    return Number(match.params.groupId);
  }

  getCachedPropertyGroup() {
    const propertyGroupId = this.getPropertyGroupId();
    const propertyGroup = loadItemFromCache<PropertyGroup>(CACHE_PROPERTY_GROUP);
    if (!propertyGroup || propertyGroup.id !== propertyGroupId) {
      return undefined;
    }
    return propertyGroup;
  }

  getCachedProperties() {
    const propertyGroupId = this.getPropertyGroupId();
    const allItems = loadListFromCache<Property>(CACHE_PROPERTIES);
    return allItems.filter((item) => propertyGroupId && item.group_id === propertyGroupId);
  }

  getColumns(): Column<Property>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
      {
        name: 'Тип',
        value: (item) => formatPropertyType(item.type),
        headClassName: 'w-200px',
      },
    ];
  }

  async loadData() {
    const propertyGroupId = this.getPropertyGroupId();
    try {
      const allItems = await sdk.getProperties();
      saveListToCache(CACHE_PROPERTIES, allItems);
      const propertyGroup = propertyGroupId ? await sdk.getPropertyGroup(propertyGroupId) : undefined;
      const items = allItems.filter((item) => propertyGroup && item.group_id === propertyGroup.id);
      this.setState({ items, propertyGroup });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(property: Property) {
    const propertyGroupId = this.getPropertyGroupId();
    await showModal(MProperty, { propertyGroupId, property });
    await this.loadData();
  }

  getMoveButtons() {
    return [
      {
        icon: 'arrow-up' as IconName,
        action: async (property: Property) => {
          try {
            await sdk.movePropertyUp(property.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down' as IconName,
        action: async (property: Property) => {
          try {
            await sdk.movePropertyDown(property.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }
}

export const PProperties = withRouter(PPropertiesBase);
