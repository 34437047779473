import React from 'react';

import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

interface P {
  title: string;
  options: Option[];
  value: string;
  close: (value?: string) => void;
}

interface S {
  form: Form;
}

export class MMultiSelect extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      form: this.initForm(),
    };
  }

  render() {
    return (
      <div className="MMultiselect modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(newForm: Form) {
    this.setState({ form: newForm });
  }

  async onAction() {
    const { close } = this.props;
    const { form } = this.state;
    close(form.getValue('checks'));
  }

  // render helpers

  renderHeader() {
    const { close, title } = this.props;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    const { options } = this.props;
    return (
      <div className="modal-body">
        <FormGroup type="checks" name="checks" form={form} options={options} onChange={(x) => this.onFormChange(x)} />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отменить" onClick={() => close()} />
        <Button type="success" text="Выбрать" onClick={() => this.onAction()} />
      </div>
    );
  }

  // other helpers

  initForm() {
    const { value } = this.props;
    return createForm({ checks: value });
  }
}
