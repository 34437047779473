import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from '../lib/utils.lib';

interface P {
  items: TabItem[];
}

interface TabItem {
  title: string;
  url: string;
  active?: boolean;
  disabled?: boolean;
}

export class Tabs extends React.Component<P> {
  render() {
    const { items } = this.props;
    return <ul className="nav nav-tabs mb-3">{items.map((x) => this.renderItem(x))}</ul>;
  }

  // render helpers

  renderItem(item: TabItem) {
    return (
      <li key={item.title} className={cn('nav-item')}>
        <Link to={item.url} className={cn('nav-link', item.active && 'active', item.disabled && 'disabled')}>
          {item.title}
        </Link>
      </li>
    );
  }
}
