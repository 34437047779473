import * as api from '../services/api.service';
import { ModelImage } from './types.sdk';

interface CreateModelImageData {
  media_id: number;
  media_hash: string;
  model_color_id: number;
  type: string;
}

export async function createModelImage(data: CreateModelImageData) {
  const result = await api.post('/model_image', data);
  return result.modelImage as ModelImage;
}

export async function deleteModelImage(id: number) {
  await api.del(`/model_image/${id}`);
}

interface ReorderModelImageData {
  model_color_id: number;
  type: string;
  image_ids: number[];
}

export async function reorderModelImages(data: ReorderModelImageData) {
  const result = await api.post('/model_image/reorder', data);
  return result.modelImages as ModelImage[];
}
