import * as api from '../services/api.service';
import { ModelStyle } from './types.sdk';

export async function getModelStyles() {
  const result = await api.get('/model_style');
  return result.modelStyles as ModelStyle[];
}

export async function getModelStyle(id: number) {
  const result = await api.get(`/model_style/${id}`);
  return result.modelStyle as ModelStyle;
}

interface CreateModelStyleData {
  name: string;
  variants: string[];
  model_generation_id: number;
}

export async function createModelStyle(data: CreateModelStyleData) {
  const result = await api.post('/model_style', data);
  return result.modelStyle as ModelStyle;
}

interface UpdateModelStyleData {
  name?: string;
  variants: string[];
  model_generation_id?: number;
}

export async function updateModelStyle(id: number, data: UpdateModelStyleData) {
  const result = await api.patch(`/model_style/${id}`, data);
  return result.modelStyle as ModelStyle;
}

export async function deleteModelStyle(id: number) {
  await api.del(`/model_style/${id}`);
}
