import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { ModelGeneration, ModelStyle } from '../../sdk';
import {
  CACHE_MODEL_GENERATION,
  CACHE_MODEL_STYLE,
  CACHE_MODEL_STYLES,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { goTo } from '../../services/router.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MModelStyle } from '../modals/MModelStyle';

interface P {
  match: match<{ brandId: string; modelId: string; generationId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  modelGeneration?: ModelGeneration;
  items: ModelStyle[];
  search: string;
}

export class PModelStylesBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      modelGeneration: this.getCachedModelGeneration(),
      items: this.getCachedModelStyles(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PModelStyles">
        {this.renderBreadcrumb()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const generationId = this.getModelGenerationId();
    await showModal<string>(MModelStyle, { modelGenerationId: generationId });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: ModelStyle) {
    const generationId = this.getModelGenerationId();
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await showModal<string>(MModelStyle, {
            modelGenerationId: generationId,
            modelStyle: item,
          });
          await this.loadData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить кузов "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteModelStyle(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: ModelStyle) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { modelGeneration } = this.state;
    const crumbs = modelGeneration
      ? [
          { title: 'Каталог', url: '/catalog' },
          { title: modelGeneration.brand_name, url: `/catalog/${modelGeneration.brand_id}` },
          {
            title: modelGeneration.model_name,
            url: `/catalog/${modelGeneration.brand_id}/${modelGeneration.model_id}`,
          },
          { title: modelGeneration.name },
        ]
      : [];
    return <Breadcrumb items={crumbs} />;
  }

  // other helpers

  getModelGenerationId() {
    const { match } = this.props;
    return Number(match.params.generationId);
  }

  getCachedModelGeneration() {
    const generationId = this.getModelGenerationId();
    const modelGeneration = loadItemFromCache<ModelGeneration>(CACHE_MODEL_GENERATION);
    if (!modelGeneration || modelGeneration.id !== generationId) {
      return undefined;
    }
    return modelGeneration;
  }

  getCachedModelStyles() {
    const generationId = this.getModelGenerationId();
    const allItems = loadListFromCache<ModelStyle>(CACHE_MODEL_STYLES);
    return allItems.filter((item) => item.model_generation_id === generationId);
  }

  getColumns(): Column<ModelStyle>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
    ];
  }

  async loadData() {
    const generationId = this.getModelGenerationId();
    try {
      const allItems = await sdk.getModelStyles();
      saveListToCache(CACHE_MODEL_STYLES, allItems);
      const modelGeneration = await sdk.getModelGeneration(generationId);
      const items = allItems.filter((item) => item.model_generation_id === modelGeneration.id);
      this.setState({ items, modelGeneration });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(modelStyle: ModelStyle) {
    saveItemToCache(CACHE_MODEL_STYLE, modelStyle);
    goTo(`/catalog/${modelStyle.brand_id}/${modelStyle.model_id}/${modelStyle.model_generation_id}/${modelStyle.id}`);
  }
}

export const PModelStyles = withRouter(PModelStylesBase);
