import * as api from '../services/api.service';
import { Company } from './types.sdk';

export async function getCompanies() {
  const result = await api.get('/company');
  return result.companies as Company[];
}

export async function getCompany(id: number) {
  const result = await api.get(`/company/${id}`);
  return result.company as Company;
}

interface CreateCompanyData {
  name: string;
}

export async function createCompany(data: CreateCompanyData) {
  const result = await api.post('/company', data);
  return result.company as Company;
}

interface UpdateCompanyData {
  name?: string;
}

export async function updateCompany(id: number, data: UpdateCompanyData) {
  const result = await api.patch(`/company/${id}`, data);
  return result.company as Company;
}

export async function deleteCompany(id: number) {
  await api.del(`/company/${id}`);
}
