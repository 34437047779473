import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { ModelColor, ModelImage, ModelVariant } from '../../sdk';
import { CACHE_MODEL_VARIANT, loadItemFromCache, saveItemToCache } from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { getModelVariantCrumbs, getModelVariantTabs, ModelVariantTab } from '../../services/navigation.service';
import { Breadcrumb } from '../Breadcrumb';
import { ImageList } from '../ImageList';
import { Select } from '../Select';
import { Tabs } from '../Tabs';

interface P {
  match: match<{
    brandId: string;
    modelId: string;
    generationId: string;
    styleId: string;
    variantId: string;
    colorId: string;
  }>;
  history: H.History;
  location: H.Location;
}

interface S {
  modelVariant?: ModelVariant;
  modelColor?: ModelColor;
  type: string;
  images: ModelImage[];
  isLoading: boolean;
}

export class PModelImagesBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      modelVariant: this.getCachedModelVariant(),
      modelColor: this.getCachedModelColor(),
      type: config.options.image_type[0].value,
      images: this.getColorImages(),
      isLoading: false,
    };
  }

  render() {
    const { isLoading, images, type } = this.state;
    const modelColor = this.getCachedModelColor();
    if (!modelColor || !images) {
      return null;
    }
    return (
      <div className="PModelImages">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        {this.renderType()}
        <ImageList
          images={images.filter((image) => image.type === type)}
          isLoading={isLoading}
          onAdd={(x) => this.onAddImage(x)}
          onReorder={(x) => this.onReorderImages(x)}
          onSaveNewOrder={() => this.onSaveReorderedImages()}
          onDelete={(x) => this.onDeleteImage(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  onTypeChange(value: string) {
    this.setState({ type: value });
  }

  async onAddImage(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      for (const file of Array.from(files)) {
        const { id, hash } = await sdk.uploadMedia(file);
        const newImage = {
          media_id: id,
          media_hash: hash,
          model_color_id: this.getModelColorId(),
          type: this.state.type,
        };
        await sdk.createModelImage(newImage);
      }
      await this.loadData();
    } catch (err) {
      handleError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async onDeleteImage(image: ModelImage) {
    const msg = 'Удалить изображение?';
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await sdk.deleteModelImage(image.id);
      await this.loadData();
    } catch (err) {
      handleError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onReorderImages(images: ModelImage[]) {
    this.setState({ images });
  }

  async onSaveReorderedImages() {
    const { type, images } = this.state;
    const modelColorId = this.getModelColorId();
    try {
      await sdk.reorderModelImages({
        model_color_id: modelColorId,
        type,
        image_ids: images.map((image) => image.id),
      });
      await this.loadData();
    } catch (err) {
      handleError(err);
    }
  }

  // render helpers

  renderBreadcrumb() {
    const { modelVariant } = this.state;
    const crumbs = getModelVariantCrumbs(modelVariant);
    return <Breadcrumb items={crumbs} />;
  }

  renderTabs() {
    const modelColor = this.getCachedModelColor();
    if (!modelColor) {
      return;
    }
    const { params } = this.props.match;
    const { brandId, modelId, generationId, styleId, variantId, colorId } = params;
    const items = [
      ...getModelVariantTabs(params, ModelVariantTab.color),
      {
        title: modelColor.name,
        url: `/catalog/${brandId}/${modelId}/${generationId}/${styleId}/${variantId}/colors/${colorId}`,
        active: true,
      },
    ];
    return <Tabs items={items} />;
  }

  renderType() {
    return (
      <Select
        className="mb-3"
        options={config.options.image_type}
        value={this.state.type}
        onChange={(value) => this.onTypeChange(value)}
      />
    );
  }

  // other helpers

  getModelVariantId() {
    const { match } = this.props;
    return Number(match.params.variantId);
  }

  getModelColorId() {
    const { match } = this.props;
    return Number(match.params.colorId);
  }

  getCachedModelVariant() {
    const modelVariantId = this.getModelVariantId();
    const modelVariant = loadItemFromCache<ModelVariant>(CACHE_MODEL_VARIANT);
    if (!modelVariant || modelVariant.id !== modelVariantId) {
      return undefined;
    }
    return modelVariant;
  }

  getCachedModelColor() {
    const modelVariant = this.getCachedModelVariant();
    if (!modelVariant || !modelVariant?.colors) {
      return undefined;
    }
    const modelColorId = this.getModelColorId();
    const modelColor = modelVariant.colors.find((color) => color.id === modelColorId);
    if (!modelColor) {
      return;
    }
    return modelColor;
  }

  getColorImages() {
    const colorId = this.getModelColorId();
    const modelVariant = this.getCachedModelVariant();
    if (!modelVariant || !modelVariant.images) {
      return [];
    }
    return modelVariant.images.filter((image) => image.model_color_id === colorId);
  }

  async loadData() {
    const variantId = this.getModelVariantId();
    const colorId = this.getModelColorId();
    try {
      const modelVariant = await sdk.getModelVariant(variantId);
      saveItemToCache(CACHE_MODEL_VARIANT, modelVariant);
      const modelColor = modelVariant.colors?.find((color) => color.id === colorId);
      if (!modelColor) {
        return;
      }
      const images = modelVariant.images?.filter((image) => image.model_color_id === colorId) ?? [];
      this.setState({ modelVariant, modelColor, images });
    } catch (e) {
      handleError(e);
    }
  }
}

export const PModelImages = withRouter(PModelImagesBase);
