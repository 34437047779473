import React from 'react';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { Property, PropertyType, PropertyDetails } from '../../sdk';
import { handleError } from '../../services/error.service';
import { formatPropertyType } from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  propertyGroupId: number;
  property?: Property;
  close: (property?: Property) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MProperty extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.property),
    };
  }

  render() {
    return (
      <div className="MProperty modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { property, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    if (this.isListType()) {
      form = form.validateRequired('details');
    }
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const freshProperty = await (property ? sdk.updateProperty(property.id, pd) : sdk.createProperty(pd));
      close(freshProperty);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, property } = this.props;
    const title = property ? property.name : 'Новое свойство';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;

    return (
      <div className="modal-body">
        <FormGroup type="text" name="name" label="Имя" required form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup
          type="select"
          name="type"
          label="Тип"
          form={form}
          options={this.getTypeOptions()}
          onChange={(x) => this.onFormChange(x)}
        />
        {this.isListType() && (
          <FormGroup
            type="textarea"
            name="details"
            label="Значения"
            required
            rows={7}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        )}
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(property?: Property) {
    if (!property) {
      return createForm({
        type: PropertyType.string,
      });
    }
    return createForm({
      name: property.name,
      status: property.type,
      details: this.getDetailsByObject(property.details),
      type: property.type,
    });
  }

  getTypeOptions() {
    return [
      { value: PropertyType.string, title: formatPropertyType(PropertyType.string) },
      { value: PropertyType.enum, title: formatPropertyType(PropertyType.enum) },
      { value: PropertyType.multi, title: formatPropertyType(PropertyType.multi) },
    ];
  }

  getDetailsByForm(value: string) {
    if (!this.isListType()) {
      return {};
    }
    return {
      options: value
        .split('\n')
        .map((str) => str.trim())
        .filter((str) => str),
    };
  }

  getDetailsByObject(details?: PropertyDetails) {
    if (!details || !details.options) {
      return '';
    }
    return details.options.join('\n');
  }

  isListType() {
    const { form } = this.state;
    return [String(PropertyType.enum), String(PropertyType.multi)].includes(form.getValue('type'));
  }

  getPostData(form: Form) {
    const { propertyGroupId } = this.props;
    return {
      group_id: propertyGroupId,
      name: form.getValue('name'),
      type: form.getValue('type') as PropertyType,
      details: this.getDetailsByForm(form.getValue('details')),
    };
  }
}
