import { runUnauthorizedRedirect } from './auth.service';

export function handleError(error: any) {
  if (error.status === 401) {
    runUnauthorizedRedirect();
  } else if (error.status === 403) {
    alert('Недостаточно прав для выполнения действия');
  } else if (error.status === 422 && error.data && error.data.code === 'foreign_constraint_error') {
    alert('Нельзя удалить объект, так как другие объекты ссылаются на него');
  } else if (error.status === 422 && error.data && error.data.code) {
    alert(`Ошибка 422 "${error.data.code}"`);
  } else {
    alert(`Ошибка: ${error.message}`);
    // eslint-disable-next-line no-console
    console.log(error);
  }
}
