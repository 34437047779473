import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from '../lib/utils.lib';

interface P {
  items: BreadcrumbItem[];
}

interface BreadcrumbItem {
  title: string;
  url?: string;
}

export class Breadcrumb extends React.Component<P> {
  render() {
    const { items } = this.props;
    return (
      <nav>
        <ol className="breadcrumb">{items.map((x, index) => this.renderItem(x, index === items.length - 1))}</ol>
      </nav>
    );
  }

  // render helpers

  renderItem(item: BreadcrumbItem, isActive: boolean) {
    return (
      <li key={item.title} className={cn('breadcrumb-item', isActive && 'active')}>
        {item.url && <Link to={item.url}>{item.title}</Link>}
        {!item.url && item.title}
      </li>
    );
  }
}
