import * as api from '../services/api.service';
import { OptionGroup } from './types.sdk';

export async function getOptionGroups() {
  const result = await api.get('/option_group');
  return result.option_groups as OptionGroup[];
}

export async function getOptionGroup(id: number) {
  const result = await api.get(`/option_group/${id}`);
  return result.option_group as OptionGroup;
}

interface CreateOptionGroupData {
  name: string;
}

export async function createOptionGroup(data: CreateOptionGroupData) {
  const result = await api.post('/option_group', data);
  return result.option_group as OptionGroup;
}

interface UpdateOptionGroupData {
  name?: string;
}

export async function updateOptionGroup(id: number, data: UpdateOptionGroupData) {
  const result = await api.patch(`/option_group/${id}`, data);
  return result.option_group as OptionGroup;
}

export async function moveOptionGroupUp(id: number) {
  await api.post(`/option_group/${id}/up`);
}

export async function moveOptionGroupDown(id: number) {
  await api.post(`/option_group/${id}/down`);
}

export async function deleteOptionGroup(id: number) {
  await api.del(`/option_group/${id}`);
}
