import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { ModelVariant, ModelColor } from '../../sdk';
import { CACHE_MODEL_VARIANT, loadItemFromCache, saveItemToCache } from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { getModelVariantCrumbs, getModelVariantTabs, ModelVariantTab } from '../../services/navigation.service';
import { goTo } from '../../services/router.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MModelColor } from '../modals/MModelColor';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ brandId: string; modelId: string; generationId: string; styleId: string; variantId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  modelVariant?: ModelVariant;
  search: string;
}

export class PModelColorsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      modelVariant: this.getCachedModelVariant(),
      search: '',
    };
  }

  render() {
    const { modelVariant, search } = this.state;
    return (
      <div className="PModelColors">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={modelVariant?.colors ?? []}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const modelVariantId = this.getModelVariantId();
    await showModal<string>(MModelColor, { modelVariantId });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: ModelColor) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          const modelVariantId = this.getModelVariantId();
          await showModal<string>(MModelColor, {
            modelVariantId,
            modelColor: item,
          });
          await this.loadData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить цвет "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteModelColor(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: ModelColor) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { modelVariant } = this.state;
    const crumbs = getModelVariantCrumbs(modelVariant);
    return <Breadcrumb items={crumbs} />;
  }

  renderTabs() {
    return <Tabs items={getModelVariantTabs(this.props.match.params, ModelVariantTab.colors)} />;
  }

  // other helpers

  getModelVariantId() {
    const { match } = this.props;
    return Number(match.params.variantId);
  }

  getCachedModelVariant() {
    const modelVariantId = this.getModelVariantId();
    const modelVariant = loadItemFromCache<ModelVariant>(CACHE_MODEL_VARIANT);
    if (!modelVariant || modelVariant.id !== modelVariantId) {
      return undefined;
    }
    return modelVariant;
  }

  getColumns(): Column<ModelColor>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
      {
        name: 'Код',
        value: (item) => item.code,
      },
    ];
  }

  async loadData() {
    const variantId = this.getModelVariantId();
    try {
      const modelVariant = await sdk.getModelVariant(variantId);
      saveItemToCache(CACHE_MODEL_VARIANT, modelVariant);
      this.setState({ modelVariant });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(modelColor: ModelColor) {
    const { modelVariant } = this.state;
    if (modelVariant) {
      goTo(
        `/catalog/${modelVariant.brand_id}/${modelVariant.model_id}/${modelVariant.model_generation_id}/${modelVariant.model_style_id}/${modelVariant.id}/colors/${modelColor.id}`,
      );
    }
  }
}

export const PModelColors = withRouter(PModelColorsBase);
