import * as api from '../services/api.service';
import { ModelColor } from './types.sdk';

interface CreateModelColorData {
  name: string;
  code: string;
  model_variant_id: number;
}

export async function createModelColor(data: CreateModelColorData) {
  const result = await api.post('/model_color', data);
  return result.modelColor as ModelColor;
}

export interface UpdateModelColorData {
  name?: string;
  code?: string;
}

export async function updateModelColor(id: number, data: UpdateModelColorData) {
  const result = await api.patch(`/model_color/${id}`, data);
  return result.modelColor as ModelColor;
}

export async function deleteModelColor(id: number) {
  await api.del(`/model_color/${id}`);
}
