import * as api from '../services/api.service';
import { Car } from './types.sdk';

interface CarQuery {
  website_id?: number;
}

export async function getCars(query: CarQuery) {
  const result = await api.get('/car', query);
  return result.cars as Car[];
}

export async function getCar(id: number) {
  const result = await api.get(`/car/${id}`);
  return result.car as Car;
}

interface CreateCarData {
  website_id: number;
  model_color_id: number;
  vin: string;
  year: number;
  price: number;
  final_price: number;
}

export async function createCar(data: CreateCarData) {
  const result = await api.post('/car', data);
  return result.car as Car;
}

export interface UpdateCarData {
  vin: string;
  year: number;
  price: number;
  final_price: number;
}

export async function updateCar(id: number, data: UpdateCarData) {
  const result = await api.patch(`/car/${id}`, data);
  return result.car as Car;
}

export async function deleteCar(id: number) {
  await api.del(`/car/${id}`);
}
