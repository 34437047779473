import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { cn, sleep } from '../lib/utils.lib';
import { Pager } from './Pager';

import './List.css';

export interface Column<T> {
  name: string;
  value: (item: T) => React.ReactNode;
  headClassName?: string;
  cellClassName?: (item: T) => string;
  ellipsis?: boolean;
}

export interface ListButton<T> {
  icon: IconProp;
  action: (item: T) => void;
}

export interface MenuItem {
  name: string;
  action: () => void;
}

interface P<T> {
  columns: Column<T>[];
  items: T[];
  className?: string;
  search?: string;
  buttons?: ListButton<T>[];
  pageSize?: number;
  pageNumber?: number;
  onGetItemClassName?: (item: T) => string;
  onGetItemMenu?: (item: T) => MenuItem[];
  onItemSelect?: (item: T) => void;
  onPageChange?: (page: number) => void;
}

export class List<T> extends React.Component<P<T>> {
  render() {
    const { className } = this.props;
    return (
      <div className={cn('List', className)}>
        {this.renderTable()}
        {this.renderPager()}
      </div>
    );
  }

  // event handlers

  onRowClick(item: T) {
    const { onItemSelect } = this.props;
    if (onItemSelect && item) {
      onItemSelect(item);
    }
  }

  onPageChange(page: number) {
    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(page);
    }
  }

  // render helpers

  renderTable() {
    const { items } = this.props;
    const className = cn('table table-sm', items.length > 0 ? 'table-hover' : '');
    return (
      <table className={className}>
        <thead>
          <tr>
            {this.renderColumns()}
            {this.renderButtonColumns()}
            {this.renderMenuColumn()}
          </tr>
        </thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }

  renderColumns() {
    const { columns } = this.props;
    return columns.map((column, index) => this.renderColumn(column, index));
  }

  renderColumn(column: Column<T>, index: number) {
    return (
      <th key={index} className={column.headClassName}>
        {column.name}
      </th>
    );
  }

  renderMenuColumn() {
    const { onGetItemMenu } = this.props;
    if (!onGetItemMenu) {
      return null;
    }
    return <th className="w-30px"></th>;
  }

  renderButtonColumns() {
    const { buttons } = this.props;
    if (!buttons) {
      return null;
    }
    return buttons.map((_item, index) => <th key={index} className="w-30px"></th>);
  }

  renderRows() {
    const items = this.getDisplayItems();
    return items.length === 0 ? this.renderRow() : items.map((item, index) => this.renderRow(item, index));
  }

  renderRow(item?: any, index?: number) {
    const { onGetItemClassName } = this.props;
    const className = onGetItemClassName && item ? onGetItemClassName(item) : undefined;
    return (
      <tr key={index} className={className}>
        {this.renderCells(item)}
        {this.renderButtonCells(item)}
        {this.renderMenuCell(item)}
      </tr>
    );
  }

  renderCells(item: any) {
    const { columns } = this.props;
    return columns.map((column, index) => this.renderCell(item, column, index));
  }

  renderCell(item: any, column: Column<T>, index: number) {
    if (!item) {
      return <td key={index}>&nbsp;</td>;
    }
    const className = column.cellClassName ? column.cellClassName(item) : undefined;
    return (
      <td key={index} className={className} onClick={() => this.onRowClick(item)}>
        {this.renderCellValue(item, column)}
      </td>
    );
  }

  renderCellValue(item: T, column: Column<T>) {
    const value = column.value(item);
    if (!value && value !== 0) {
      return '—';
    }
    if (column.ellipsis) {
      return <div className="List_ellipsis">{value}</div>;
    }
    return value;
  }

  renderMenuCell(item: T) {
    const { onGetItemMenu } = this.props;
    if (!onGetItemMenu) {
      return null;
    }
    if (!item) {
      return <td></td>;
    }
    const menu = onGetItemMenu(item);
    return (
      <td className="text-center">
        <div className="dropdown">
          <button className="List_button" data-toggle="dropdown">
            <FontAwesomeIcon icon="bars" />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {menu.map((menuItem) => this.renderMenuItem(menuItem))}
          </div>
        </div>
      </td>
    );
  }

  renderButtonCells(item: any) {
    const { buttons } = this.props;
    if (!buttons) {
      return null;
    }
    if (!item) {
      return <td></td>;
    }
    return (
      <React.Fragment>{buttons.map((button, index) => this.renderButtonItem(item, button, index))}</React.Fragment>
    );
  }

  renderMenuItem(menuItem: MenuItem) {
    const clickHandler = async () => {
      await sleep(); // allows to hide the menu before `window.confirm`
      menuItem.action();
    };
    return (
      <button key={menuItem.name} className="dropdown-item" onClick={clickHandler}>
        {menuItem.name}
      </button>
    );
  }

  renderButtonItem(item: T, button: ListButton<T>, buttonIndex: number) {
    const clickHandler = () => button.action(item);
    return (
      <td key={buttonIndex} className="text-center">
        <button className="List_button" onClick={clickHandler}>
          <FontAwesomeIcon icon={button.icon as IconProp} />
        </button>
      </td>
    );
  }

  renderPager() {
    const { pageSize, pageNumber } = this.props;
    if (!pageSize) {
      return null;
    }
    const items = this.getMatchedItems();
    if (items.length <= pageSize) {
      return null;
    }
    return (
      <Pager
        total={items.length}
        pageSize={pageSize}
        pageNumber={pageNumber || 1}
        onSelect={(page) => this.onPageChange(page)}
      />
    );
  }

  // other helpers

  getDisplayItems() {
    const { pageSize, pageNumber } = this.props;
    const matchedItems = this.getMatchedItems();
    if (!pageSize) {
      return matchedItems;
    }
    const offset = ((pageNumber || 1) - 1) * pageSize;
    return matchedItems.slice(offset, offset + pageSize);
  }

  getMatchedItems() {
    // "$" is a special field in items for search
    const { items, search } = this.props;
    if (!search) {
      return items;
    }
    if (items.length === 0) {
      return [];
    }
    if ((items[0] as any).$ === undefined) {
      return items;
    }
    const lowerSearch = search.toLowerCase();
    return items.filter((item) => (item as any).$.includes(lowerSearch));
  }
}
