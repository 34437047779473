import React from 'react';

import { SyncReport } from '../../sdk';
import { createForm } from '../../services/form.service';
import { getSyncReportText } from '../../services/syncReport.service';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  syncReport: SyncReport;
  close: () => void;
}

export class MSyncReport extends React.Component<P> {
  render() {
    return (
      <div className="MSyncReport modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { syncReport, close } = this.props;
    return <ModalHeader title={`Отчёт # ${syncReport.id}`} close={close} />;
  }

  renderBody() {
    const { syncReport } = this.props;
    const form = createForm({
      report: getSyncReportText(syncReport),
    });
    return (
      <div className="modal-body">
        <FormGroup type="textarea" rows={16} name="report" form={form} className="m-0" disabled />
      </div>
    );
  }
}
