import * as api from '../services/api.service';
import { Media, TicketComment } from './types.sdk';

interface CreateTicketCommentData {
  ticket_id: number;
  body: string;
  attachments?: Media[];
}

export async function createTicketComment(data: CreateTicketCommentData) {
  const result = await api.post('/ticket_comment', data);
  return result.ticketComment as TicketComment;
}

export async function deleteTicketComment(id: number) {
  await api.del(`/ticket_comment/${id}`);
}
