import React from 'react';

import { getUser, login, logout } from '../../services/auth.service';
import { createForm, Form } from '../../services/form.service';
import { LSK_EMAIL, setLsItem } from '../../services/ls.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';

import './PLogin.css';

interface S {
  isLoading: boolean;
  form: Form;
}

export class PLogin extends React.Component<unknown, S> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      form: createForm(),
      isLoading: false,
    };
  }

  render() {
    const { form, isLoading } = this.state;
    return (
      <div className="PLogin align-items-center d-flex h-100 justify-content-center">
        <div className="card bg-light w-25">
          <h4 className="card-header">Двигатель</h4>
          <form className="card-body">
            <FormGroup
              type="text"
              name="email"
              placeholder="Email"
              form={form}
              onChange={(x) => this.onFormChange(x)}
            />
            <FormGroup
              type="password"
              name="password"
              placeholder="Пароль"
              form={form}
              onChange={(x) => this.onFormChange(x)}
              onSubmit={() => this.onLogin()}
            />
            <Button
              className="w-100 mb-2"
              type="primary"
              text="Вход"
              disabled={!this.isFilled(form) || isLoading}
              onClick={() => this.onLogin()}
            />
          </form>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (getUser()) {
      logout();
    }
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form: form.resetErrors() });
  }

  async onLogin() {
    const { form } = this.state;
    if (!this.isFilled(form)) {
      return;
    }
    const email = form.getValue('email');
    const password = form.getValue('password');
    this.setState({ isLoading: true });
    try {
      await login(email, password);
      setLsItem(LSK_EMAIL, email);
    } catch (e) {
      alert(e.status === 401 ? 'Неверный email или пароль' : e.message);
      this.setState({ isLoading: false });
    }
  }

  // other helpers

  isFilled(form: Form) {
    return Boolean(form.getValue('email')) && Boolean(form.getValue('password'));
  }
}
