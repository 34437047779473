import React from 'react';

import * as sdk from '../../sdk';
import { Company } from '../../sdk';
import {
  CACHE_COMPANIES,
  CACHE_COMPANY,
  loadListFromCache,
  saveItemToCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { goTo } from '../../services/router.service';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MCompany } from '../modals/MCompany';

interface S {
  items: Company[];
  search: string;
}

export class PCompanies extends React.Component<unknown, S> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      items: loadListFromCache(CACHE_COMPANIES),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PCompanies">
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    await showModal(MCompany);
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: Company) {
    return [
      {
        name: 'Открыть',
        action: async () => {
          await this.runPrimaryAction(item);
        },
      },
      {
        name: 'Редактировать',
        action: async () => {
          await showModal(MCompany, { company: item });
          await this.loadData();
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить клиента "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteCompany(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: Company) {
    await this.runPrimaryAction(item);
  }

  // other helpers

  getColumns(): Column<Company>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
    ];
  }

  async loadData() {
    try {
      const items = await sdk.getCompanies();
      this.setState({ items });
      saveListToCache(CACHE_COMPANIES, items);
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(company: Company) {
    saveItemToCache(CACHE_COMPANY, company);
    goTo(`/companies/${company.id}/websites`);
  }
}
