import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from '../lib/utils.lib';
import { getUser, isCompanyUser, isSystemUser, logout } from '../services/auth.service';
import { isCurrentPath } from '../services/router.service';
import { VoidLink } from './VoidLink';

export class Navbar extends React.Component {
  render() {
    const user = getUser();
    return (
      <nav className="Navbar navbar navbar-expand navbar-light bg-light">
        <div className="container">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              {isSystemUser() && (
                <React.Fragment>
                  {this.renderNavItem('/companies', 'Клиенты')}
                  {this.renderNavItem('/tickets', 'Обращения')}
                  {this.renderNavItem('/users', 'Пользователи')}
                  {this.renderNavItem('/catalog', 'Каталог')}
                  {this.renderNavItem('/properties', 'Характеристики')}
                  {this.renderNavItem('/options', 'Опции')}
                </React.Fragment>
              )}
              {isCompanyUser() && (
                <React.Fragment>
                  {this.renderNavItem('/websites', 'Сайты')}
                  {this.renderNavItem('/users', 'Пользователи')}
                  {this.renderNavItem('/tickets', 'Обращения')}
                </React.Fragment>
              )}
            </ul>
            <div className="Navbar_userDropdown dropdown">
              <button
                className="btn btn-outline-secondary btn-sm dropdown-toggle"
                id="navbarDropdown"
                data-toggle="dropdown"
              >
                {user ? user.email : 'User'}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <VoidLink className="dropdown-item" onClick={() => logout()}>
                  Выход
                </VoidLink>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }

  // event handlers

  async onClickVersion() {
    // TODO
  }

  // render helpers

  renderNavItem(url: string, title: string) {
    const isActive = isCurrentPath(url);
    return (
      <li className={cn('nav-item', isActive && 'active')}>
        <Link to={url} className="nav-link">
          {title}
        </Link>
      </li>
    );
  }
}
