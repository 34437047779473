export function areEqual(x: any, y: any) {
  return JSON.stringify(x) === JSON.stringify(y);
}

export function cn(...parts: any[]) {
  return parts.filter((p) => p).join(' ');
}

export function esc(value: string) {
  let result = value;
  result = replace(result, '&', '&amp;');
  result = replace(result, '<', '&lt;');
  result = replace(result, '>', '&gt;');
  result = replace(result, '"', '&quot;');
  result = replace(result, "'", '&#39;');
  result = replace(result, '`', '&#96;');
  return result;
}

export function linkify(text: string) {
  return text.replace(/(https?:\/\/\S+)/g, '<a href="$1" target="_blank">$1</a>');
}

export function replace(value: string, search: string, replacement: string) {
  return value.split(search).join(replacement);
}

export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function sortBy<T, V>(array: T[], by: (item: T) => V): T[] {
  const sorted = [...array];
  sorted.sort((a, b) => {
    const av = by(a);
    const bv = by(b);
    if (av > bv) return 1;
    if (av < bv) return -1;
    return 0;
  });
  return sorted;
}
