import { SyncReport, SyncReportItem } from '../sdk';
import { EMPTY, formatDateTime, formatMoney } from './fmt.service';

export function getSyncReportText(syncReport: SyncReport) {
  return getSyncReportLines(syncReport).join('\n').trim();
}

// private

function getSyncReportLines(syncReport: SyncReport) {
  const lines: string[] = [];
  lines.push(`Дата и время: ${formatDateTime(syncReport.created_at)}`);
  lines.push(`Автомобилей всего: ${syncReport.items_total}`);
  lines.push(`Автомобилей опубликовано: ${syncReport.items_valid - syncReport.items_ignored}`);
  lines.push('');
  for (const item of syncReport.items || []) {
    lines.push(...getSyncReportItemLines(item));
    lines.push('');
  }
  return lines;
}

function getSyncReportItemLines(syncReportItem: SyncReportItem) {
  const lines: string[] = [];
  if (syncReportItem.is_ignored) {
    return lines;
  }
  lines.push(`[${syncReportItem.position}] VIN: ${syncReportItem.vin || EMPTY}`);
  if (syncReportItem.error) {
    lines.push(syncReportItem.error);
    return lines;
  }
  if (syncReportItem.is_new) {
    lines.push('Добавлен впервые');
    return lines;
  }
  const keys = Object.keys(syncReportItem.diff);
  if (keys.length === 0) {
    lines.push('Опубликован без изменений');
    return lines;
  }
  lines.push(...getDiffLines(syncReportItem.diff));
  return lines;
}

function getDiffLines(diff: any) {
  const keys = Object.keys(diff);
  return keys.map((key) => {
    const [v1, v2] = diff[key];
    return `${formatKey(key)}: ${formatValue(key, v1)} → ${formatValue(key, v2)}`;
  });
}

function formatKey(key: string) {
  const map: { [key: string]: string } = {
    price: 'Цена',
    final_price: 'Спеццена',
    year: 'Год',
  };
  return map[key] || key;
}

function formatValue(key: string, value: any) {
  if (!value) {
    return '✗';
  }
  if (['price', 'final_price'].includes(key)) {
    return formatMoney(value);
  }
  return value;
}
