import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ModelImage } from '../sdk';
import { cn } from '../lib/utils.lib';
import { ImageListItem } from './ImageListItem';

import './ImageList.css';

interface P {
  images: ModelImage[];
  isLoading: boolean;
  onAdd: (file: FileList) => void;
  onReorder: (imageIds: ModelImage[]) => void;
  onSaveNewOrder: () => void;
  onDelete: (image: ModelImage) => void;
}

interface S {
  isDrag: boolean;
}

export class ImageList extends React.Component<P, S> {
  dragImage?: ModelImage;

  constructor(props: P) {
    super(props);
    this.state = {
      isDrag: false,
    };
  }

  render() {
    return <div className="ImageList">{this.renderList()}</div>;
  }

  // event handlers

  onDragStart(image: ModelImage) {
    this.dragImage = image;
    this.setState({ isDrag: true });
  }

  onDragEnd() {
    const { onSaveNewOrder } = this.props;
    this.dragImage = undefined;
    onSaveNewOrder();
  }

  onDragEnter(image: ModelImage) {
    const { images, onReorder } = this.props;
    if (!this.dragImage) {
      return;
    }
    const indexTo = images.indexOf(image);
    const indexFrom = images.indexOf(this.dragImage);
    if (indexTo === -1 || indexFrom === -1) {
      return;
    }
    const newList = [...images];
    const movedImage = newList.splice(indexFrom, 1)[0];
    newList.splice(indexTo, 0, movedImage);
    onReorder(newList);
  }

  onMouseOver() {
    const { isDrag } = this.state;
    if (!this.dragImage && isDrag) {
      this.setState({ isDrag: false });
    }
  }

  async onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { onAdd } = this.props;
    const files = e.target.files;
    if (!files) {
      return;
    }
    onAdd(files);
  }

  onDelete(image: ModelImage) {
    const { onDelete } = this.props;
    onDelete(image);
  }

  // render helpers

  renderList() {
    const { isLoading } = this.props;
    const { isDrag } = this.state;
    return (
      <div className={cn('ImageList_container', isDrag && '_drag')}>
        {this.renderImages()}
        {isLoading ? this.renderLoad() : this.renderAdd()}
      </div>
    );
  }

  renderImages() {
    const { images } = this.props;
    return images.map((image) => this.renderImage(image));
  }

  renderImage(image: ModelImage) {
    return (
      <ImageListItem
        key={image.media_name}
        className="ImageList_item"
        image={image}
        onDelete={(x) => this.onDelete(x)}
        onDragStart={(x) => this.onDragStart(x)}
        onDragEnter={(x) => this.onDragEnter(x)}
        onDragEnd={() => this.onDragEnd()}
        onMouseOver={() => this.onMouseOver()}
      />
    );
  }

  renderLoad() {
    return (
      <div className="card ImageList_item">
        <div className="card-body ImageList_spinner">
          <div className="spinner-border text-primary" />
        </div>
      </div>
    );
  }

  renderAdd() {
    return (
      <div className="card ImageList_item">
        <div className="card-body">
          <label className="ImageList_input" htmlFor="imageInput">
            <input type="file" className="d-none" id="imageInput" multiple onChange={(e) => this.onFileChange(e)} />
            <FontAwesomeIcon icon="plus" size="3x" />
          </label>
        </div>
      </div>
    );
  }
}
