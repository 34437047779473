import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';
import * as sdk from '../../sdk';

import { Account, AccountRole, Company } from '../../sdk';
import { isSystemUser } from '../../services/auth.service';
import {
  CACHE_COMPANY,
  CACHE_USERS,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { showModal } from '../../services/modal.service';
import { CompanyTab, getCompanyCrumbs, getCompanyTabs } from '../../services/navigation.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MAccount } from '../modals/MAccount';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ companyId?: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  company?: Company;
  items: Account[];
  search: string;
}

export class PUsersBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      company: this.getCachedCompany(),
      items: this.getCachedUsers(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PUsers">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          onGetItemClassName={(x) => this.onGetItemClassName(x)}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const companyId = this.getCompanyId();
    await showModal(MAccount, { companyId });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemClassName(item: Account) {
    return item.is_active ? '' : 'text-black-50';
  }

  onGetItemMenu(item: Account) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await this.runPrimaryAction(item);
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить пользователя "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteAccount(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: Account) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { company } = this.state;
    if (!this.getCompanyId()) {
      return null;
    }
    return <Breadcrumb items={getCompanyCrumbs(company)} />;
  }

  renderTabs() {
    const companyId = this.getCompanyId();
    if (!companyId) {
      return null;
    }
    return <Tabs items={getCompanyTabs(companyId, CompanyTab.users)} />;
  }

  // other helpers

  getCompanyId() {
    const { match } = this.props;
    return Number(match.params.companyId) || undefined;
  }

  getCachedCompany() {
    const companyId = this.getCompanyId();
    if (!companyId) {
      return undefined;
    }
    const company = loadItemFromCache<Company>(CACHE_COMPANY);
    if (!company) {
      return undefined;
    }
    if (company.id !== companyId) {
      return undefined;
    }
    return company;
  }

  getCachedUsers() {
    return this.getCompanyId() ? [] : loadListFromCache<Account>(CACHE_USERS);
  }

  getColumns(): Column<Account>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
      {
        name: 'Email',
        value: (item) => item.email,
      },
    ];
  }

  async loadData() {
    const companyId = this.getCompanyId();
    try {
      const items = await sdk.getAccounts({
        company_id: companyId,
        role: isSystemUser() && !companyId ? AccountRole.system_admin : undefined,
      });
      const company = companyId ? await sdk.getCompany(companyId) : undefined;
      this.setState({ items, company });
      if (!companyId) {
        saveListToCache(CACHE_USERS, items);
      }
      saveItemToCache(CACHE_COMPANY, company);
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(account: Account) {
    const companyId = this.getCompanyId();
    await showModal(MAccount, { companyId, account });
    await this.loadData();
  }
}

export const PUsers = withRouter(PUsersBase);
