import * as api from '../services/api.service';
import { ModelVariant } from './types.sdk';

export async function getModelVariants() {
  const result = await api.get('/model_variant');
  return result.modelVariants as ModelVariant[];
}

export async function getModelVariant(id: number) {
  const result = await api.get(`/model_variant/${id}`);
  return result.modelVariant as ModelVariant;
}

interface CreateModelVariantData {
  name: string;
  code: string | null;
  model_style_id: number;
  engine_type?: string | null;
  transmission_type?: string | null;
  drive?: string | null;
  motor_volume?: number | null;
  motor_power?: number | null;
  price?: number | null;
}

export async function createModelVariant(data: CreateModelVariantData) {
  const result = await api.post('/model_variant', data);
  return result.modelVariant as ModelVariant;
}

export interface UpdateModelVariantData {
  name?: string;
  code?: string | null;
  engine_type?: string | null;
  transmission_type?: string | null;
  drive?: string | null;
  motor_volume?: number | null;
  motor_power?: number | null;
  price?: number | null;
  options?: number[];
  properties?: Array<{ id: number; value: string }>;
}

export async function updateModelVariant(id: number, data: UpdateModelVariantData) {
  const result = await api.patch(`/model_variant/${id}`, data);
  return result.modelVariant as ModelVariant;
}

interface CopyModelVariantData {
  name: string;
}

export async function copyModelVariant(id: number, data: CopyModelVariantData) {
  const result = await api.post(`/model_variant/${id}/copy`, data);
  return result.modelVariant as ModelVariant;
}

export async function copyModelVariantImages(sourceId: number, destId: number) {
  const result = await api.post(`/model_variant/${sourceId}/copy_images`, {
    to_model_variant_id: destId,
  });
  return result.count as number;
}

export async function deleteModelVariant(id: number) {
  await api.del(`/model_variant/${id}`);
}

export async function moveModelVariantUp(id: number) {
  await api.post(`/model_variant/${id}/up`);
}

export async function moveModelVariantDown(id: number) {
  await api.post(`/model_variant/${id}/down`);
}
