import React from 'react';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { Drive, EngineType, ModelStyle, ModelVariant, TransmissionType } from '../../sdk';
import { handleError } from '../../services/error.service';
import {
  formatDrive,
  formatEngineType,
  formatMoney,
  formatMotorVolumeInLitres,
  formatTransmissionType,
} from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

const NULL_OPTION_MALE: Option = {
  value: '',
  title: 'Не выбран',
};

const NULL_OPTION_FEMALE: Option = {
  value: '',
  title: 'Не выбрана',
};

interface P {
  modelStyle: ModelStyle;
  modelVariant?: ModelVariant;
  close: (modelVariant?: ModelVariant) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MModelVariant extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.modelVariant),
    };
  }

  render() {
    return (
      <div className="MModelVariant modal-dialog  modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(newForm: Form) {
    this.setState({ form: newForm });
  }

  async onSave() {
    const { modelVariant, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const freshModelVariant = await (modelVariant
        ? sdk.updateModelVariant(modelVariant.id, pd)
        : sdk.createModelVariant(pd));
      close(freshModelVariant);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, modelVariant } = this.props;
    const title = modelVariant ? modelVariant.name : 'Новая комплектация';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;

    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup
            type="select"
            name="name"
            label="Имя"
            required
            className="col-sm-6"
            options={this.getNameOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            className="col-sm-6"
            name="code"
            label="Код"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            type="select"
            name="engine_type"
            label="Тип двигателя"
            className="col-sm-6"
            options={this.getEngineOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="select"
            name="transmission_type"
            label="Коробка передач"
            className="col-sm-6"
            options={this.getTransmissionOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            type="select"
            name="drive"
            label="Привод"
            className="col-sm-6"
            options={this.getDriveOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="select"
            name="motor_volume"
            label="Объём двигателя"
            className="col-sm-6"
            options={this.getMotorVolumeOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            type="uint"
            name="motor_power"
            label="Мощность двигателя, л.с."
            className="col-sm-6"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="text"
            name="price"
            className="col-sm-6"
            label="Базовая цена, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(modelVariant?: ModelVariant) {
    if (!modelVariant) {
      return createForm({});
    }
    return createForm({
      name: modelVariant.name,
      code: modelVariant.code,
      price: formatMoney(modelVariant.price),
      engine_type: modelVariant.engine_type,
      transmission_type: modelVariant.transmission_type,
      drive: modelVariant.drive,
      motor_power: modelVariant.motor_power,
      motor_volume: modelVariant.motor_volume,
    });
  }

  getPostData(form: Form) {
    const { modelStyle } = this.props;
    return {
      model_style_id: modelStyle.id,
      name: form.getValue('name'),
      code: form.getValue('code') || null,
      engine_type: form.getValue('engine_type') || null,
      transmission_type: form.getValue('transmission_type') || null,
      drive: form.getValue('drive') || null,
      motor_volume: form.getNumericValue('motor_volume') || null,
      motor_power: form.getNumericValue('motor_power') || null,
      price: form.getMoneyValue('price') || null,
    };
  }

  getEngineOptions(): Option[] {
    return [NULL_OPTION_MALE].concat([
      { value: EngineType.petrol, title: formatEngineType(EngineType.petrol) },
      { value: EngineType.diesel, title: formatEngineType(EngineType.diesel) },
      { value: EngineType.hybrid, title: formatEngineType(EngineType.hybrid) },
    ]);
  }

  getTransmissionOptions(): Option[] {
    return [NULL_OPTION_FEMALE].concat([
      { value: TransmissionType.mt, title: formatTransmissionType(TransmissionType.mt) },
      { value: TransmissionType.at, title: formatTransmissionType(TransmissionType.at) },
      { value: TransmissionType.cvt, title: formatTransmissionType(TransmissionType.cvt) },
    ]);
  }

  getDriveOptions(): Option[] {
    return [NULL_OPTION_MALE].concat([
      { value: Drive.fwd, title: formatDrive(Drive.fwd) },
      { value: Drive.rwd, title: formatDrive(Drive.rwd) },
      { value: Drive.xwd, title: formatDrive(Drive.xwd) },
    ]);
  }

  getMotorVolumeOptions(): Option[] {
    return [NULL_OPTION_MALE].concat([
      ...config.options.motor_volume.map((x) => ({ value: String(x), title: `${formatMotorVolumeInLitres(x)} л` })),
    ]);
  }

  getNameOptions(): Option[] {
    const { modelStyle, modelVariant } = this.props;
    const options: Option[] = [];
    if (!modelVariant) {
      options.push({ value: '', title: 'Выбрать', disabled: true });
    }
    options.push(
      ...modelStyle.variants.map((x) => ({
        value: x,
        title: x,
      })),
    );
    if (modelVariant && !modelStyle.variants.includes(modelVariant.name)) {
      options.push({
        value: modelVariant.name,
        title: modelVariant.name,
        disabled: true,
      });
    }
    return options;
  }
}
