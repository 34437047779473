import * as api from '../services/api.service';
import { ModelGeneration } from './types.sdk';

export async function getModelGenerations() {
  const result = await api.get('/model_generation');
  return result.modelGenerations as ModelGeneration[];
}

export async function getModelGeneration(id: number) {
  const result = await api.get(`/model_generation/${id}`);
  return result.modelGeneration as ModelGeneration;
}

interface CreateModelGenerationData {
  name: string;
  model_id: number;
  year_from: number;
  year_to?: number | null;
}

export async function createModelGeneration(data: CreateModelGenerationData) {
  const result = await api.post('/model_generation', data);
  return result.modelGeneration as ModelGeneration;
}

interface UpdateModelGenerationData {
  name?: string;
  model_id?: number;
  year_from?: number;
  year_to?: number | null;
}

export async function updateModelGeneration(id: number, data: UpdateModelGenerationData) {
  const result = await api.patch(`/model_generation/${id}`, data);
  return result.modelGeneration as ModelGeneration;
}

export async function deleteModelGeneration(id: number) {
  await api.del(`/model_generation/${id}`);
}
