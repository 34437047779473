import * as api from '../services/api.service';
import { Brand } from './types.sdk';

export async function getBrands() {
  const result = await api.get('/brand');
  return result.brands as Brand[];
}

export async function getBrand(id: number) {
  const result = await api.get(`/brand/${id}`);
  return result.brand as Brand;
}

interface CreateBrandData {
  name: string;
}

export async function createBrand(data: CreateBrandData) {
  const result = await api.post('/brand', data);
  return result.brand as Brand;
}

interface UpdateBrandData {
  name?: string;
}

export async function updateBrand(id: number, data: UpdateBrandData) {
  const result = await api.patch(`/brand/${id}`, data);
  return result.brand as Brand;
}

export async function deleteBrand(id: number) {
  await api.del(`/brand/${id}`);
}
