import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import { areEqual } from '../../lib/utils.lib';
import * as sdk from '../../sdk';
import { ModelVariant, Property, PropertyGroup, UpdateModelVariantData } from '../../sdk';
import {
  CACHE_MODEL_VARIANT,
  CACHE_PROPERTIES,
  CACHE_PROPERTY_GROUPS,
  loadItemFromCache,
  loadListFromCache,
  saveListToCache,
  saveItemToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { getModelVariantCrumbs, getModelVariantTabs, ModelVariantTab } from '../../services/navigation.service';
import { scrollToTop } from '../../services/ui.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { ModelVariantPropertyGroups } from '../ModelVariantPropertyGroups';
import { PageFooter } from '../PageFooter';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ brandId: string; modelId: string; generationId: string; styleId: string; variantId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  modelVariant?: ModelVariant;
  properties: Property[];
  propertyGroups: PropertyGroup[];
  form: Form;
  isLoading: boolean;
}

export class PModelVariantPropertiesBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    const modelVariant = this.getCachedModelVariant();
    const properties = this.getCachedProperties();
    this.state = {
      modelVariant,
      properties,
      propertyGroups: this.getCachedPropertyGroups(),
      form: this.initForm(modelVariant, properties),
      isLoading: false,
    };
  }

  render() {
    return (
      <div className="PModelVariantProperties">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        {this.renderForm()}
        {this.renderFooter()}
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { modelVariant } = this.state;
    if (!modelVariant) {
      return;
    }
    let { form } = this.state;
    form = form.trimValues();
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const freshModelVariant = await sdk.updateModelVariant(modelVariant.id, pd);
      this.setState({ modelVariant: freshModelVariant });
      saveItemToCache(CACHE_MODEL_VARIANT, freshModelVariant);
      scrollToTop(true);
    } catch (err) {
      handleError(err);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onCancel() {
    const { modelVariant, properties } = this.state;
    const form = this.initForm(modelVariant, properties);
    this.setState({ properties, form });
  }

  // render helpers

  renderBreadcrumb() {
    const { modelVariant } = this.state;
    const crumbs = getModelVariantCrumbs(modelVariant);
    return <Breadcrumb items={crumbs} />;
  }

  renderTabs() {
    return <Tabs items={getModelVariantTabs(this.props.match.params, ModelVariantTab.properties)} />;
  }

  renderForm() {
    const { form, propertyGroups, properties } = this.state;
    return (
      <ModelVariantPropertyGroups
        propertyGroups={propertyGroups}
        properties={properties}
        form={form}
        isContentReady={this.isContentReady()}
        onFormChange={(x) => this.onFormChange(x)}
      />
    );
  }

  renderFooter() {
    const { isLoading } = this.state;
    const isFormUpdated = this.isFormUpdated();
    return (
      <PageFooter>
        {[
          isFormUpdated && (
            <Button
              key="cancel"
              type="secondary"
              text="Отменить"
              disabled={isLoading}
              onClick={() => this.onCancel()}
            />
          ),
          <Button
            key="save"
            type="success"
            text="Сохранить"
            disabled={isLoading || !isFormUpdated}
            onClick={() => this.onSave()}
          />,
        ]}
      </PageFooter>
    );
  }

  // other helpers

  getModelVariantId() {
    const { match } = this.props;
    return Number(match.params.variantId);
  }

  getCachedModelVariant() {
    const modelVariantId = this.getModelVariantId();
    const modelVariant = loadItemFromCache<ModelVariant>(CACHE_MODEL_VARIANT);
    if (!modelVariant || modelVariant.id !== modelVariantId) {
      return undefined;
    }
    return modelVariant;
  }

  getCachedPropertyGroups() {
    return loadListFromCache<PropertyGroup>(CACHE_PROPERTY_GROUPS);
  }

  getCachedProperties() {
    return loadListFromCache<Property>(CACHE_PROPERTIES);
  }

  initForm(modelVariant: ModelVariant | undefined, properties: Property[]) {
    if (!modelVariant) {
      return createForm({});
    }
    const formData: { [key: string]: string | undefined } = {};
    const propertyData: { [key: string]: string } = {};
    modelVariant.properties?.forEach((property) => {
      propertyData[property.id] = property.value;
    });
    properties.forEach((property) => {
      formData[`property_${property.id}`] = propertyData[property.id] || undefined;
    });
    return createForm(formData);
  }

  async loadData() {
    const variantId = this.getModelVariantId();
    try {
      const [modelVariant, properties, propertyGroups] = await Promise.all([
        sdk.getModelVariant(variantId),
        sdk.getProperties(),
        sdk.getPropertyGroups(),
      ]);
      saveItemToCache(CACHE_MODEL_VARIANT, modelVariant);
      saveListToCache(CACHE_PROPERTIES, properties);
      saveListToCache(CACHE_PROPERTY_GROUPS, propertyGroups);
      const form = this.initForm(modelVariant, properties);
      this.setState({ modelVariant, properties, propertyGroups, form });
    } catch (e) {
      handleError(e);
    }
  }

  getPostData(form: Form) {
    const { properties } = this.state;
    const propertiesData = form.getValues();
    return {
      properties: properties.reduce<UpdateModelVariantData['properties']>((memo, property) => {
        if (propertiesData[`property_${property.id}`]) {
          memo!.push({
            id: property.id,
            value: propertiesData[`property_${property.id}`],
          });
        }
        return memo;
      }, []),
    };
  }

  isFormUpdated() {
    const { form, modelVariant, properties } = this.state;
    if (!modelVariant) {
      return false;
    }
    const initialForm = this.initForm(modelVariant, properties);
    return !areEqual(initialForm.getValues(), form.getValues());
  }

  isContentReady() {
    return !!this.state.modelVariant?.properties;
  }
}

export const PModelVariantProperties = withRouter(PModelVariantPropertiesBase);
