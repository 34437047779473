import { Company, ModelVariant, Website } from './../sdk';

export enum ModelVariantTab {
  properties = 'properties',
  options = 'options',
  colors = 'colors',
  color = 'color',
}

export enum CompanyTab {
  users = 'users',
  tickets = 'tickets',
  ticket = 'ticket',
  websites = 'websites',
}

export enum WebsiteTab {
  cars = 'cars',
  syncReports = 'syncReports',
}

export function getModelVariantTabs(
  params: { brandId: string; modelId: string; generationId: string; styleId: string; variantId: string },
  activeTab: ModelVariantTab,
) {
  const { brandId, modelId, generationId, styleId, variantId } = params;
  const prefix = `/catalog/${brandId}/${modelId}/${generationId}/${styleId}/${variantId}`;
  return [
    { title: 'Характеристики', url: `${prefix}/properties`, active: activeTab === ModelVariantTab.properties },
    { title: 'Опции', url: `${prefix}/options`, active: activeTab === ModelVariantTab.options },
    { title: 'Цвета', url: `${prefix}/colors`, active: activeTab === ModelVariantTab.colors },
  ];
}

export function getModelVariantCrumbs(modelVariant: ModelVariant | undefined) {
  if (!modelVariant) {
    return [];
  }
  return [
    { title: 'Каталог', url: '/catalog' },
    { title: modelVariant.brand_name, url: `/catalog/${modelVariant.brand_id}` },
    { title: modelVariant.model_name, url: `/catalog/${modelVariant.brand_id}/${modelVariant.model_id}` },
    {
      title: modelVariant.model_generation_name,
      url: `/catalog/${modelVariant.brand_id}/${modelVariant.model_id}/${modelVariant.model_generation_id}`,
    },
    {
      title: modelVariant.model_style_name,
      url: `/catalog/${modelVariant.brand_id}/${modelVariant.model_id}/${modelVariant.model_generation_id}/${modelVariant.model_style_id}`,
    },
    { title: modelVariant.name },
  ];
}

export function getCompanyTabs(companyId: number, activeTab: CompanyTab) {
  const prefix = `/companies/${companyId}`;
  const tabs = [
    { title: 'Сайты', url: `${prefix}/websites`, active: activeTab === CompanyTab.websites },
    { title: 'Пользователи', url: `${prefix}/users`, active: activeTab === CompanyTab.users },
    { title: 'Обращения', url: `${prefix}/tickets`, active: activeTab === CompanyTab.tickets },
  ];
  return tabs;
}

export function getWebsiteTabs(websiteId: number, activeTab: WebsiteTab, companyId?: number) {
  const prefix = companyId ? `/companies/${companyId}/websites/${websiteId}` : `/websites/${websiteId}`;
  const tabs = [
    { title: 'Автомобили', url: `${prefix}/cars`, active: activeTab === WebsiteTab.cars },
    { title: 'Отчёты', url: `${prefix}/reports`, active: activeTab === WebsiteTab.syncReports },
  ];
  return tabs;
}

export function getCompanyCrumbs(company: Company | undefined) {
  if (!company) {
    return [];
  }
  return [{ title: 'Клиенты', url: '/companies' }, { title: company.name }];
}

export function getWebsiteCrumbs(website: Website, companyId?: number) {
  if (!companyId) {
    return [{ title: 'Сайты', url: `/websites` }, { title: website.name }];
  }
  return [
    { title: 'Клиенты', url: '/companies' },
    { title: website.company_name, url: `/companies/${website.company_id}/websites` },
    { title: website.name },
  ];
}

export function getTicketCrumbs(ticketId: number) {
  return [{ title: 'Обращения', url: '/tickets' }, { title: `# ${ticketId}` }];
}
