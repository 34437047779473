import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import * as sdk from '../../sdk';
import { PropertyGroup } from '../../sdk';
import {
  CACHE_PROPERTY_GROUPS,
  CACHE_PROPERTY_GROUP,
  loadListFromCache,
  saveListToCache,
  saveItemToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { goTo } from '../../services/router.service';
import { showModal } from '../../services/modal.service';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MPrompt } from '../modals/MPrompt';

interface S {
  items: PropertyGroup[];
  search: string;
}

export class PPropertyGroups extends React.Component<unknown, S> {
  constructor(props: unknown) {
    super(props);
    this.state = {
      items: loadListFromCache(CACHE_PROPERTY_GROUPS),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PPropertyGroups">
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          buttons={this.getMoveButtons()}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const name = await showModal<string>(MPrompt, {
      label: 'Имя',
      title: 'Новая группа',
      action: 'Сохранить',
      isRequired: true,
    });
    if (name) {
      try {
        await sdk.createPropertyGroup({ name });
        await this.loadData();
      } catch (err) {
        handleError(err);
      }
    }
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemMenu(item: PropertyGroup) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          const name = await showModal<string>(MPrompt, {
            label: 'Имя',
            title: item.name,
            action: 'Сохранить',
            defaultValue: item.name,
            isRequired: true,
          });
          if (name) {
            try {
              await sdk.updatePropertyGroup(item.id, { name });
              await this.loadData();
            } catch (err) {
              handleError(err);
            }
          }
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить группу "${item.name}"?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deletePropertyGroup(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: PropertyGroup) {
    await this.runPrimaryAction(item);
  }

  async runPrimaryAction(propertyGroup: PropertyGroup) {
    saveItemToCache(CACHE_PROPERTY_GROUP, propertyGroup);
    goTo(`/properties/${propertyGroup.id}`);
  }

  // other helpers

  getColumns(): Column<PropertyGroup>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
    ];
  }

  async loadData() {
    try {
      const items = await sdk.getPropertyGroups();
      this.setState({ items });
      saveListToCache(CACHE_PROPERTY_GROUPS, items);
    } catch (e) {
      handleError(e);
    }
  }

  getMoveButtons() {
    return [
      {
        icon: 'arrow-up' as IconName,
        action: async (propertyGroup: PropertyGroup) => {
          try {
            await sdk.movePropertyGroupUp(propertyGroup.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down' as IconName,
        action: async (propertyGroup: PropertyGroup) => {
          try {
            await sdk.movePropertyGroupDown(propertyGroup.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }
}
