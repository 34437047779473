import * as api from '../services/api.service';
import { Media, Ticket } from './types.sdk';

interface TicketQuery {
  company_id?: number;
}

export async function getTickets(query: TicketQuery = {}) {
  const result = await api.get('/ticket', query);
  return result.tickets as Ticket[];
}

export async function getTicket(id: number) {
  const result = await api.get(`/ticket/${id}`);
  return result.ticket as Ticket;
}

interface CreateTicketData {
  company_id: number;
  title: string;
  body: string;
  attachments?: Media[];
}

export async function createTicket(data: CreateTicketData) {
  const result = await api.post('/ticket', data);
  return result.ticket as Ticket;
}

export async function closeTicket(id: number) {
  await api.post(`/ticket/${id}/close`);
}

export async function reopenTicket(id: number) {
  await api.post(`/ticket/${id}/reopen`);
}

export async function deleteTicket(id: number) {
  await api.del(`/ticket/${id}`);
}
