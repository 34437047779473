import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { Car, CarSource, Website } from '../../sdk';
import { requireUser } from '../../services/auth.service';
import {
  CACHE_CARS,
  CACHE_WEBSITE,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { formatMoney } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import { getWebsiteCrumbs, getWebsiteTabs, WebsiteTab } from '../../services/navigation.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List } from '../List';
import { MCar } from '../modals/MCar';
import { Select } from '../Select';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ companyId?: string; websiteId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  website?: Website;
  items: Car[];
  search: string;
  filterSource: string;
  filterHidden: string;
}

export class PCarsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      website: this.getCachedWebsite(),
      items: this.getCachedCars(),
      search: '',
      filterSource: '',
      filterHidden: '',
    };
  }

  render() {
    const { items, search, filterSource, filterHidden } = this.state;
    const filteredItems = items.filter(
      (item) =>
        (!filterSource || item.source === filterSource) && (!filterHidden || String(item.is_hidden) === filterHidden),
    );
    return (
      <div className="PCars">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
          {this.renderFilter()}
        </div>
        <List
          items={filteredItems}
          columns={this.getColumns()}
          search={search}
          onGetItemClassName={(x) => this.onGetItemClassName(x)}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const companyId = this.getCompanyId();
    const websiteId = this.getWebsiteId();
    const user = requireUser();
    await showModal(MCar, { companyId: companyId || user.company_id, websiteId });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemClassName(item: Car) {
    return !item.is_hidden ? '' : 'text-black-50';
  }

  onFilterSourceChange(value: string) {
    this.setState({ filterSource: value });
  }

  onFilterHiddenChange(value: string) {
    this.setState({ filterHidden: value });
  }

  onGetItemMenu(item: Car) {
    return [
      {
        name: 'Редактировать',
        action: async () => {
          await this.runPrimaryAction(item);
        },
      },
      {
        name: 'Удалить',
        action: async () => {
          const msg = `Удалить автомобиль?`;
          if (!window.confirm(msg)) {
            return;
          }
          try {
            await sdk.deleteCar(item.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }

  async onItemSelect(item: Car) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { website } = this.state;
    if (!website) {
      return null;
    }
    const companyId = this.getCompanyId();
    return <Breadcrumb items={getWebsiteCrumbs(website, companyId)} />;
  }

  renderTabs() {
    const companyId = this.getCompanyId();
    const websiteId = this.getWebsiteId();
    return <Tabs items={getWebsiteTabs(websiteId, WebsiteTab.cars, companyId)} />;
  }

  renderFilter() {
    return (
      <React.Fragment>
        <Select
          options={this.getFilterSourceOptions()}
          className="ml-3"
          value={this.state.filterSource}
          onChange={(value) => this.onFilterSourceChange(value)}
        />
        <Select
          options={this.getFilterHiddenOptions()}
          className="ml-3"
          value={this.state.filterHidden}
          onChange={(value) => this.onFilterHiddenChange(value)}
        />
      </React.Fragment>
    );
  }

  // other helpers

  getCompanyId() {
    const { match } = this.props;
    return Number(match.params.companyId) || undefined;
  }

  getWebsiteId() {
    const { match } = this.props;
    return Number(match.params.websiteId);
  }

  getCachedWebsite() {
    const websiteId = this.getWebsiteId();
    const website = loadItemFromCache<Website>(CACHE_WEBSITE);
    if (!website) {
      return undefined;
    }
    if (website.id !== websiteId) {
      return undefined;
    }
    return website;
  }

  getCachedCars() {
    return this.getCompanyId() ? [] : loadListFromCache<Car>(CACHE_CARS);
  }

  getColumns(): Column<Car>[] {
    return [
      {
        name: 'Марка',
        value: (item) => item.brand_name,
      },
      {
        name: 'Модель',
        value: (item) => item.model_name,
      },
      {
        name: 'Год',
        value: (item) => item.year,
      },
      {
        name: 'Цена',
        value: (item) => formatMoney(item.final_price, false, true),
      },
    ];
  }

  async loadData() {
    const websiteId = this.getWebsiteId();
    try {
      const items = await sdk.getCars({
        website_id: websiteId,
      });
      const website = await sdk.getWebsite(websiteId);
      saveItemToCache(CACHE_WEBSITE, website);
      this.setState({ items, website });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(car: Car) {
    const companyId = this.getCompanyId();
    const user = requireUser();
    await showModal(MCar, { companyId: companyId || user.company_id, car });
    await this.loadData();
  }

  getFilterSourceOptions() {
    return [
      {
        value: '',
        title: 'Все источники',
      },
      {
        value: CarSource.auto,
        title: 'Из файла',
      },
      {
        value: CarSource.manual,
        title: 'Добавленные вручную',
      },
    ];
  }

  getFilterHiddenOptions() {
    return [
      {
        value: '',
        title: 'Все статусы',
      },
      {
        value: 'false',
        title: 'Активные',
      },
      {
        value: 'true',
        title: 'Скрытые',
      },
    ];
  }
}

export const PCars = withRouter(PCarsBase);
