import * as api from '../services/api.service';
import { Website } from './types.sdk';

interface WebsiteQuery {
  company_id?: number;
}

export async function getWebsites(query: WebsiteQuery) {
  const result = await api.get('/website', query);
  return result.websites as Website[];
}

export async function getWebsite(id: number) {
  const result = await api.get(`/website/${id}`);
  return result.website as Website;
}

interface CreateWebsiteData {
  name: string;
  company_id: number;
  sync_url?: string;
}

export async function createWebsite(data: CreateWebsiteData) {
  const result = await api.post('/website', data);
  return result.website as Website;
}

interface UpdateWebsiteData {
  name?: string;
  sync_url?: string;
}

export async function updateWebsite(id: number, data: UpdateWebsiteData) {
  const result = await api.patch(`/website/${id}`, data);
  return result.website as Website;
}

export async function deleteWebsite(id: number) {
  await api.del(`/website/${id}`);
}
