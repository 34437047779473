import * as api from '../services/api.service';
import { Model } from './types.sdk';

export async function getModels() {
  const result = await api.get('/model');
  return result.models as Model[];
}

export async function getModel(id: number) {
  const result = await api.get(`/model/${id}`);
  return result.model as Model;
}

interface CreateModelData {
  name: string;
  brand_id: number;
}

export async function createModel(data: CreateModelData) {
  const result = await api.post('/model', data);
  return result.model as Model;
}

interface UpdateModelData {
  name?: string;
  brand_id?: number;
}

export async function updateModel(id: number, data: UpdateModelData) {
  const result = await api.patch(`/model/${id}`, data);
  return result.model as Model;
}

export async function deleteModel(id: number) {
  await api.del(`/model/${id}`);
}
