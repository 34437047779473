import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { config } from '../../config';
import { Company, SyncReport, Website } from '../../sdk';
import {
  CACHE_COMPANIES,
  CACHE_SYNC_REPORTS,
  CACHE_WEBSITE,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { formatDate, formatTime } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import { getWebsiteCrumbs, getWebsiteTabs, WebsiteTab } from '../../services/navigation.service';
import { Breadcrumb } from '../Breadcrumb';
import { Column, List } from '../List';
import { MSyncReport } from '../modals/MSyncReport';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ companyId?: string; websiteId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  website?: Website;
  companies: Company[];
  items: SyncReport[];
  page: number;
  search: string;
}

export class PSyncReportsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      website: this.getCachedWebsite(),
      companies: loadListFromCache(CACHE_COMPANIES),
      items: this.getCachedSyncReports(),
      page: 1,
      search: '',
    };
  }

  render() {
    const { items, search, page } = this.state;
    return (
      <div className="PSyncReports">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        <div className="d-flex mb-3">
          <input
            className="form-control"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          onItemSelect={(x) => this.onItemSelect(x)}
          pageSize={config.itemsPerPage}
          pageNumber={page}
          onPageChange={(page) => this.onPageChange(page)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  // event handlers

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onPageChange(page: number) {
    this.setState({ page });
  }

  async onItemSelect(item: SyncReport) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { website } = this.state;
    const companyId = this.getCompanyId();
    if (!website) {
      return null;
    }
    return <Breadcrumb items={getWebsiteCrumbs(website, companyId)} />;
  }

  renderTabs() {
    const companyId = this.getCompanyId();
    const websiteId = this.getWebsiteId();
    return <Tabs items={getWebsiteTabs(websiteId, WebsiteTab.syncReports, companyId)} />;
  }

  // other helpers

  getCompanyId() {
    const { match } = this.props;
    return Number(match.params.companyId) || undefined;
  }

  getWebsiteId() {
    const { match } = this.props;
    return Number(match.params.websiteId);
  }

  getCachedWebsite() {
    const websiteId = this.getWebsiteId();
    const website = loadItemFromCache<Website>(CACHE_WEBSITE);
    if (!website) {
      return undefined;
    }
    if (website.id !== websiteId) {
      return undefined;
    }
    return website;
  }

  getCachedSyncReports() {
    return this.getCompanyId() ? [] : loadListFromCache<SyncReport>(CACHE_SYNC_REPORTS);
  }

  getColumns() {
    const columns: Column<SyncReport>[] = [
      {
        name: '#',
        value: (item: SyncReport) => item.id,
      },
      {
        name: 'Дата',
        value: (item) => formatDate(item.created_at),
      },
      {
        name: 'Время',
        value: (item) => formatTime(item.created_at),
      },
      {
        name: 'Автомобили',
        value: (item) => item.items_total,
      },
      {
        name: 'Опубликовано',
        value: (item) => item.items_valid - item.items_ignored,
      },
    ];
    return columns;
  }

  async refreshData() {
    const websiteId = this.getWebsiteId();
    try {
      const items = await sdk.getSyncReports({
        website_id: websiteId,
      });
      const website = await sdk.getWebsite(websiteId);
      saveItemToCache(CACHE_WEBSITE, website);
      this.setState({ items, website });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(item: SyncReport) {
    try {
      const syncReport = await sdk.getSyncReport(item.id);
      await showModal(MSyncReport, { syncReport });
    } catch (err) {
      handleError(err);
    }
  }
}

export const PSyncReports = withRouter(PSyncReportsBase);
