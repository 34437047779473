import { config } from '../config';

export const LSK_EMAIL = 'email';
export const LSK_START_URL = 'start_url';
export const LSK_TOKEN = 'token';
export const LSK_USER = 'user';

const LS_VERSION = 'version';

export function initLs() {
  if (getLsItem(LS_VERSION) !== config.lsVersion) {
    clearLs();
  }
}

export function getLsItem<T>(key: string) {
  const lsKey = getLsKey(key);
  const value = localStorage.getItem(lsKey);
  if (value === null) {
    return undefined;
  }
  return JSON.parse(value) as T;
}

export function setLsItem(key: string, value: any) {
  const lsKey = getLsKey(key);
  if (value === undefined) {
    localStorage.removeItem(lsKey);
  } else {
    localStorage.setItem(lsKey, JSON.stringify(value));
  }
}

export function clearLs() {
  localStorage.clear();
  setLsItem(LS_VERSION, config.lsVersion);
}

// private

function getLsKey(key: string) {
  return `carsale_${key}`;
}
