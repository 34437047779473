import React from 'react';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { ModelGeneration } from '../../sdk';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

const MIN_YEAR = 1900;

interface P {
  modelId: number;
  modelGeneration?: ModelGeneration;
  close: (modelGeneration?: ModelGeneration) => void;
}

interface S {
  form: Form;
  isLoading: boolean;
}

export class MModelGeneration extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.modelGeneration),
    };
  }

  render() {
    return (
      <div className="MModelGeneration modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(newForm: Form) {
    const { form } = this.state;
    if (newForm.getValue('year_from') !== form.getValue('year_from')) {
      newForm = newForm.resetErrors();
    }
    this.setState({ form: newForm });
  }

  async onSave() {
    const { modelGeneration, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('name');
    form = form.validateRequired('year_from');
    form = this.validatePeriod(form);
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const pd = this.getPostData(form);
      const freshModelGeneration = await (modelGeneration
        ? sdk.updateModelGeneration(modelGeneration.id, pd)
        : sdk.createModelGeneration(pd));
      close(freshModelGeneration);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, modelGeneration } = this.props;
    const title = modelGeneration ? modelGeneration.name : 'Новое поколение';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;

    return (
      <div className="modal-body">
        <FormGroup type="text" name="name" label="Имя" required form={form} onChange={(x) => this.onFormChange(x)} />
        <div className="row">
          <FormGroup
            type="uint"
            name="year_from"
            label="Год с"
            className="col-sm-6"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            type="uint"
            className="col-sm-6"
            name="year_to"
            label="Год по"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(modelGeneration?: ModelGeneration) {
    if (!modelGeneration) {
      return createForm({});
    }
    return createForm({
      name: modelGeneration.name,
      year_from: modelGeneration.year_from,
      year_to: modelGeneration.year_to,
    });
  }

  getPostData(form: Form) {
    const { modelId } = this.props;
    const yearFrom = form.getNumericValue('year_from');
    const yearTo = form.getNumericValue('year_to');
    return {
      model_id: modelId,
      name: form.getValue('name'),
      year_from: yearFrom || 0,
      year_to: yearTo === undefined ? null : yearTo,
    };
  }

  validatePeriod(form: Form) {
    const now = new Date();
    const yearFrom = form.getNumericValue('year_from');
    form = form.validateInteger('year_from', MIN_YEAR, now.getFullYear());
    form = form.validateInteger('year_to', yearFrom, now.getFullYear());
    return form;
  }
}
