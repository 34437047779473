import * as dateFns from 'date-fns';

import { Drive, EngineType, ModelGeneration, PropertyType, Ticket, TransmissionType } from '../sdk/types.sdk';

export const EMPTY = '—';

export const PROPERTY_TYPE_MAP = {
  string: 'Строка',
  enum: 'Список',
  multi: 'Мультисписок',
};

export const TRANSMISSION_TYPE_MAP = {
  mt: 'Механическая',
  at: 'Автоматическая',
  cvt: 'Вариатор',
};

export const ENGINE_TYPE_MAP = {
  petrol: 'Бензин',
  diesel: 'Дизель',
  hybrid: 'Гибрид',
};

export const DRIVE_MAP = {
  fwd: 'Передний',
  rwd: 'Задний',
  '4wd': 'Полный',
};

export function formatDate(date: string | null | undefined) {
  if (!date) {
    return '';
  }
  return dateFns.format(new Date(date), 'dd.MM.yyyy');
}

export function formatDateTime(date: string | null | undefined) {
  if (!date) {
    return '';
  }
  return dateFns.format(new Date(date), 'dd.MM.yyyy HH:mm');
}

export function formatDrive(type: Drive) {
  return DRIVE_MAP[type];
}

export function formatEngineType(type: EngineType) {
  return ENGINE_TYPE_MAP[type];
}

export function formatModelGenerationPeriod(item: ModelGeneration) {
  return `${item.year_from} – ${item.year_to || 'н.в.'}`;
}

export function formatMoney(value: number | null | undefined, withKop = false, withSign = false) {
  if (value == null) {
    return '';
  }
  return formatNumber(value / 100, withKop ? 2 : 0) + (withSign ? ' ₽' : '');
}

export function formatMotorVolumeInLitres(value: number | null) {
  if (value === null) {
    return '';
  }
  return (value / 1000).toFixed(1);
}

export function formatNumber(value: number | null | undefined, fractionDigits = 0) {
  if (value == null) {
    return '';
  }
  const isNegative = value < 0;
  const absValue = Math.abs(value);
  const src = String(fractionDigits > 0 ? Math.trunc(absValue) : Math.round(absValue));
  let dst = '';
  const n = src.length;
  for (let i = 0; i < n; i += 1) {
    if (i % 3 === 0 && i !== 0) {
      dst = ' ' + dst;
    }
    dst = src.charAt(n - i - 1) + dst;
  }
  if (fractionDigits > 0) {
    const fraction = absValue - Math.trunc(absValue);
    dst += fraction.toFixed(fractionDigits).substr(1);
  }
  return isNegative ? `-${dst}` : dst;
}

export function formatPropertyType(type: PropertyType) {
  return PROPERTY_TYPE_MAP[type];
}

export function formatTicketStatus(ticket: Ticket) {
  return ticket.started_at ? 'В работе' : 'Новое';
}

export function formatTime(date: string | null | undefined) {
  if (!date) {
    return '';
  }
  return dateFns.format(new Date(date), 'HH:mm');
}

export function formatTransmissionType(type: TransmissionType) {
  return TRANSMISSION_TYPE_MAP[type];
}

export function formatTransmissionTypeShort(type: TransmissionType) {
  return type.toUpperCase();
}
