import { version } from '../package.json';

export const config = {
  version,
  api: getApi(),
  itemsPerPage: 100,
  invalidFormMessage: 'Пожалуйста, исправьте неверно заполненные поля',
  lsVersion: 1,
  options: {
    motor_volume: [1200, 1300, 1400, 1500, 1600, 1800, 2000, 2200, 2500, 3000, 3200, 3500],
    image_type: [
      { value: 'rendered', title: 'Рендеренные фото' },
      { value: 'live', title: 'Живые фото' },
    ],
  },
};

function getApi() {
  if (window.location.hostname === 'app.dvgtl.com') {
    return 'https://api.dvgtl.com';
  }
  return 'https://carsale-api.cranbee.pro';
}
