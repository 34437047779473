import React from 'react';

import { config } from '../../config';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup, FormGroupType } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  title: string;
  label: string;
  action: string;
  inputType?: FormGroupType;
  defaultValue?: string;
  isRequired?: boolean;
  onValidate?: (form: Form, name: string) => Form;
  close: (value?: string) => void;
}

interface S {
  form: Form;
}

export class MPrompt extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      form: createForm({ name: props.defaultValue }),
    };
  }

  render() {
    return (
      <div className="MPrompt modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onAction() {
    const { close, isRequired, onValidate } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    if (isRequired) {
      form = form.validateRequired('name');
    }
    if (onValidate) {
      form = onValidate(form, 'name');
    }
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    close(form.getValue('name'));
  }

  // render helpers

  renderHeader() {
    const { close, title } = this.props;
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    const { label, isRequired, inputType = 'text' } = this.props;
    return (
      <div className="modal-body">
        <FormGroup
          type={inputType}
          name="name"
          label={label}
          autoFocus
          required={Boolean(isRequired)}
          form={form}
          onChange={(x) => this.onFormChange(x)}
          onSubmit={() => this.onAction()}
        />
      </div>
    );
  }

  renderFooter() {
    const { close, action } = this.props;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отменить" onClick={() => close()} />
        <Button type="success" text={action} onClick={() => this.onAction()} />
      </div>
    );
  }
}
