import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { cn, esc, linkify } from '../lib/utils.lib';
import { AccountRole, TicketComment, TicketCommentMedia } from '../sdk';
import { requireUser } from '../services/auth.service';
import { formatDateTime } from '../services/fmt.service';
import { getMediaUrl } from '../services/media.service';
import { VoidLink } from './VoidLink';

interface P {
  comment: TicketComment;
  attachments: TicketCommentMedia[];
  onDelete: (comment: TicketComment) => void;
}

export class TicketCommentItem extends React.Component<P> {
  render() {
    const { comment } = this.props;
    const isAdmin = comment.author_role === AccountRole.system_admin;
    const commentClassName = cn('alert', isAdmin ? 'alert-primary ml-25' : 'alert-info mr-25');
    return (
      <div className={commentClassName}>
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div className="m-0 font-weight-bold">{comment.author_name}</div>
          <div className="d-flex align-items-center">
            {this.renderDropdownComment()}
            <div>{formatDateTime(comment.created_at)}</div>
          </div>
        </div>
        <p className="m-0 ws-pre-line" dangerouslySetInnerHTML={{ __html: linkify(esc(comment.body || '')) }}></p>
        {this.renderAttachments()}
      </div>
    );
  }

  // render helpers

  renderAttachments() {
    const { attachments } = this.props;
    if (!attachments.length) {
      return null;
    }
    return (
      <p className="mb-0 mt-2">
        {attachments.map((a) => (
          <a
            key={a.media_id}
            href={getMediaUrl(a.media_name, a.original_file_name)}
            className="badge badge-light mr-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {a.original_file_name}
          </a>
        ))}
      </p>
    );
  }

  renderDropdownComment() {
    if (!this.isAdmin()) {
      return null;
    }
    const { comment, onDelete } = this.props;
    return (
      <div className="dropdown d-flex align-items-center ml-auto pr-3">
        <button className="bg-transparent border-0 p-0 outline-none o-25" data-toggle="dropdown">
          <FontAwesomeIcon icon="ellipsis-h" className="icon-button" />
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <VoidLink className="dropdown-item" onClick={() => onDelete(comment)}>
            Удалить
          </VoidLink>
        </div>
      </div>
    );
  }

  // other helpers

  isAdmin() {
    const user = requireUser();
    return user.role === AccountRole.system_admin;
  }
}
