import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { cn } from '../lib/utils.lib';
import { getUser, isSystemUser, runUnauthorizedRedirect } from '../services/auth.service';
import { getModals, Modal } from '../services/modal.service';
import { getHistory } from '../services/router.service';
import { registerUiUpdater } from '../services/ui.service';
import { Navbar } from './Navbar';
import { PBrands } from './pages/PBrands';
import { PCars } from './pages/PCars';
import { PCompanies } from './pages/PCompanies';
import { PLogin } from './pages/PLogin';
import { PModelColors } from './pages/PModelColors';
import { PModelGenerations } from './pages/PModelGenerations';
import { PModelImages } from './pages/PModelImages';
import { PModels } from './pages/PModels';
import { PModelStyles } from './pages/PModelStyles';
import { PModelVariantOptions } from './pages/PModelVariantOptions';
import { PModelVariantProperties } from './pages/PModelVariantProperties';
import { PModelVariants } from './pages/PModelVariants';
import { POptionGroups } from './pages/POptionGroups';
import { POptions } from './pages/POptions';
import { PProperties } from './pages/PProperties';
import { PPropertyGroups } from './pages/PPropertyGroups';
import { PSyncReports } from './pages/PSyncReports';
import { PTicket } from './pages/PTicket';
import { PTickets } from './pages/PTickets';
import { PUsers } from './pages/PUsers';
import { PWebsites } from './pages/PWebsites';

export class App extends React.Component {
  render() {
    return (
      <Router history={getHistory()}>
        <Switch>
          <Route path="/login">
            <PLogin />
          </Route>
          <Auth />
        </Switch>
      </Router>
    );
  }

  componentDidMount() {
    registerUiUpdater(() => {
      this.forceUpdate();
    });
  }
}

function Auth() {
  if (!getUser()) {
    runUnauthorizedRedirect();
    return null;
  }
  return (
    <div>
      <Navbar />
      <div className="container mt-3">
        <Switch>
          <Route path="/companies" exact>
            <PCompanies />
          </Route>
          <Route path="/companies/:companyId/websites" exact>
            <PWebsites key="companyWebsites" />
          </Route>
          <Route path="/companies/:companyId/websites/:websiteId/cars" exact>
            <PCars key="companyCars" />
          </Route>
          <Route path="/companies/:companyId/websites/:websiteId/reports" exact>
            <PSyncReports key="companySyncReports" />
          </Route>
          <Route path="/companies/:companyId/users" exact>
            <PUsers key="companyUsers" />
          </Route>
          <Route path="/companies/:companyId/tickets" exact>
            <PTickets key="companyTickets" />
          </Route>
          <Route path="/companies/:companyId/tickets/:ticketId" exact>
            <PTicket />
          </Route>
          <Route path="/tickets" exact>
            <PTickets key="topTickets" />
          </Route>
          <Route path="/tickets/:ticketId" exact>
            <PTicket />
          </Route>
          <Route path="/users" exact>
            <PUsers key="topUsers" />
          </Route>
          <Route path="/catalog" exact>
            <PBrands />
          </Route>
          <Route path="/catalog/:brandId" exact>
            <PModels />
          </Route>
          <Route path="/catalog/:brandId/:modelId" exact>
            <PModelGenerations />
          </Route>
          <Route path="/catalog/:brandId/:modelId/:generationId" exact>
            <PModelStyles />
          </Route>
          <Route path="/catalog/:brandId/:modelId/:generationId/:styleId" exact>
            <PModelVariants />
          </Route>
          <Route
            path="/catalog/:brandId/:modelId/:generationId/:styleId/:variantId"
            exact
            render={({ match: { url } }) => <Redirect to={`${url}/properties`} />}
          />
          <Route path="/catalog/:brandId/:modelId/:generationId/:styleId/:variantId/properties" exact>
            <PModelVariantProperties />
          </Route>
          <Route path="/catalog/:brandId/:modelId/:generationId/:styleId/:variantId/options" exact>
            <PModelVariantOptions />
          </Route>
          <Route path="/catalog/:brandId/:modelId/:generationId/:styleId/:variantId/colors" exact>
            <PModelColors />
          </Route>
          <Route path="/catalog/:brandId/:modelId/:generationId/:styleId/:variantId/colors/:colorId" exact>
            <PModelImages />
          </Route>
          <Route path="/properties" exact>
            <PPropertyGroups />
          </Route>
          <Route path="/properties/:groupId" exact>
            <PProperties />
          </Route>
          <Route path="/options" exact>
            <POptionGroups />
          </Route>
          <Route path="/options/:groupId" exact>
            <POptions />
          </Route>
          <Route path="/websites" exact>
            <PWebsites key="topWebsites" />
          </Route>
          <Route path="/websites/:websiteId/cars" exact>
            <PCars key="topCars" />
          </Route>
          <Route path="/websites/:websiteId/reports" exact>
            <PSyncReports key="topSyncReports" />
          </Route>
          <Route path="*">
            <RefirectToHome />
          </Route>
        </Switch>
      </div>
      {renderModals()}
    </div>
  );
}

function RefirectToHome() {
  const url = isSystemUser() ? '/companies' : '/websites';
  return <Redirect to={url} />;
}

function renderModals() {
  const modals = getModals();
  const n = modals.length;
  if (n === 0) {
    return null;
  }
  return (
    <div>
      {modals.map((m, i) => renderModal(m, i === n - 1))}
      <div key="backdrop" className="modal-backdrop show"></div>
    </div>
  );
}

function renderModal(modal: Modal, isVisible: boolean) {
  const { component, props, key } = modal;
  const className = cn('modal', isVisible ? 'd-block' : '');
  return (
    <div key={key} className={className}>
      {React.createElement(component, props)}
    </div>
  );
}
