import * as api from '../services/api.service';
import { SyncReport } from './types.sdk';

interface SyncReportQuery {
  website_id?: number;
}

export async function getSyncReports(query: SyncReportQuery = {}) {
  const result = await api.get('/sync_report', query);
  return result.sync_reports as SyncReport[];
}

export async function getSyncReport(id: number) {
  const result = await api.get(`/sync_report/${id}`);
  return result.sync_report as SyncReport;
}
