import React from 'react';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { requireUser } from '../../services/auth.service';
import { handleError } from '../../services/error.service';
import { createForm, Form } from '../../services/form.service';
import { AttachmentArea } from '../AttachmentArea';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';

interface P {
  companyId: number;
  close: () => void;
}

interface S {
  form: Form;
  isLoading: boolean;
  files: File[];
}

export class MTicket extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: createForm(),
      files: [],
    };
  }

  render() {
    return (
      <div className="MTicket modal-dialog">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onFormChange(form: Form) {
    this.setState({ form });
  }

  async onSave() {
    const { close } = this.props;
    const { files } = this.state;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('title');
    form = form.validateRequired('body');
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    const attachments = [];
    try {
      for (const file of files) {
        const media = await sdk.uploadMedia(file);
        attachments.push(media);
      }
      const pd = this.getPostData(form);
      await sdk.createTicket({ ...pd, attachments });
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  onChangeAttachments(files: File[]) {
    this.setState({ files });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    return <ModalHeader title="Новое обращение" close={close} />;
  }

  renderBody() {
    const { form, isLoading, files } = this.state;
    return (
      <div className="modal-body">
        <FormGroup type="text" name="title" label="Тема" required form={form} onChange={(x) => this.onFormChange(x)} />
        <FormGroup
          type="textarea"
          name="body"
          label="Текст"
          required
          form={form}
          onChange={(x) => this.onFormChange(x)}
        />
        <AttachmentArea files={files} disabled={isLoading} onChange={(files) => this.onChangeAttachments(files)} />
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Отправить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  getPostData(form: Form) {
    const user = requireUser();
    let { companyId } = this.props;
    if (user.company_id) {
      companyId = user.company_id;
    }
    return {
      company_id: companyId,
      title: form.getValue('title'),
      body: form.getValue('body'),
    };
  }
}
