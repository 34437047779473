import * as api from '../services/api.service';
import { PropertyGroup } from './types.sdk';

export async function getPropertyGroups() {
  const result = await api.get('/property_group');
  return result.property_groups as PropertyGroup[];
}

export async function getPropertyGroup(id: number) {
  const result = await api.get(`/property_group/${id}`);
  return result.property_group as PropertyGroup;
}

interface CreatePropertyGroupData {
  name: string;
}

export async function createPropertyGroup(data: CreatePropertyGroupData) {
  const result = await api.post('/property_group', data);
  return result.property_group as PropertyGroup;
}

interface UpdatePropertyGroupData {
  name?: string;
}

export async function updatePropertyGroup(id: number, data: UpdatePropertyGroupData) {
  const result = await api.patch(`/property_group/${id}`, data);
  return result.property_group as PropertyGroup;
}

export async function movePropertyGroupUp(id: number) {
  await api.post(`/property_group/${id}/up`);
}

export async function movePropertyGroupDown(id: number) {
  await api.post(`/property_group/${id}/down`);
}

export async function deletePropertyGroup(id: number) {
  await api.del(`/property_group/${id}`);
}
