import * as api from '../services/api.service';
import { Account } from './types.sdk';

export async function getUser() {
  const result = await api.get('/auth');
  return result.user as Account;
}

export async function login(email: string, password: string) {
  const result = await api.post('/auth', { email, password });
  return result.token as string;
}
