import React from 'react';

import { cn } from '../lib/utils.lib';
import { Button } from './Button';

import './AttachmentItem.css';

interface P {
  className?: string;
  disabled?: boolean;
  file: File;
  onRemove: () => void;
}

export class AttachmentItem extends React.Component<P> {
  render() {
    const { file, className, disabled, onRemove } = this.props;
    const groupClassName = cn('btn-group AttachmentItem', className, disabled && '_disabled');
    return (
      <div className={groupClassName} title={file.name} role="group">
        <Button className="btn-sm AttachmentItem_label" type="outline-secondary" disabled={disabled} text={file.name} />
        <Button
          className="pt-0 pb-0 pl-1 pr-1"
          type="outline-secondary"
          disabled={disabled}
          icon="times-circle"
          onClick={() => onRemove()}
        />
      </div>
    );
  }
}
