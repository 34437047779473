import React from 'react';

import { config } from '../../config';
import * as sdk from '../../sdk';
import { Brand, Car, Model, ModelGeneration, ModelStyle, ModelVariant } from '../../sdk';
import {
  CACHE_BRANDS,
  CACHE_MODELS,
  CACHE_MODEL_GENERATIONS,
  CACHE_MODEL_STYLES,
  CACHE_MODEL_VARIANTS,
  loadListFromCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import {
  formatEngineType,
  formatMoney,
  formatMotorVolumeInLitres,
  formatTransmissionTypeShort,
} from '../../services/fmt.service';
import { createForm, Form } from '../../services/form.service';
import { Button } from '../Button';
import { FormGroup } from '../FormGroup';
import { ModalHeader } from '../ModalHeader';
import { Option } from '../Select';

const MIN_YEAR = 1900;

const NULL_OPTION: Option = {
  value: '',
  title: 'Не выбрано',
};
const NULL_OPTION_MALE: Option = {
  value: '',
  title: 'Не выбран',
};
const NULL_OPTION_FEMALE: Option = {
  value: '',
  title: 'Не выбрана',
};

interface P {
  car?: Car;
  close: (car?: Car) => void;
  websiteId: number;
}

interface S {
  form: Form;
  brands: Brand[];
  models: Model[];
  modelGenerations: ModelGeneration[];
  modelStyles: ModelStyle[];
  modelVariants: ModelVariant[];
  modelVariant: ModelVariant | null;
  isLoading: boolean;
}

export class MCar extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isLoading: false,
      form: this.initForm(props.car),
      brands: loadListFromCache<Brand>(CACHE_BRANDS),
      models: loadListFromCache<Model>(CACHE_MODELS),
      modelGenerations: loadListFromCache<ModelGeneration>(CACHE_MODEL_GENERATIONS),
      modelStyles: loadListFromCache<ModelStyle>(CACHE_MODEL_STYLES),
      modelVariants: loadListFromCache<ModelVariant>(CACHE_MODEL_VARIANTS),
      modelVariant: null,
    };
  }

  render() {
    return (
      <div className="MCar modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  // event handlers

  onChangeBrand(form: Form) {
    form = form.setValue('model_id', '');
    return this.onChangeModel(form);
  }

  onChangeModel(form: Form) {
    form = form.setValue('model_generation_id', '');
    return this.onChangeModelGeneration(form);
  }

  onChangeModelGeneration(form: Form) {
    form = form.setValue('model_style_id', '');
    return this.onChangeModelStyle(form);
  }

  onChangeModelStyle(form: Form) {
    form = form.setValue('model_variant_id', '');
    return this.onChangeModelVariant(form);
  }

  onChangeModelVariant(form: Form) {
    form = form.setValue('model_color_id', '');
    this.setState({ modelVariant: null });
    return form;
  }

  async componentDidMount() {
    await this.loadData();
  }

  async onFormChange(newForm: Form) {
    const { form } = this.state;
    if (form.getValue('brand_id') !== newForm.getValue('brand_id')) {
      newForm = this.onChangeBrand(newForm);
    } else if (form.getValue('model_id') !== newForm.getValue('model_id')) {
      newForm = this.onChangeModel(newForm);
    } else if (form.getValue('model_generation_id') !== newForm.getValue('model_generation_id')) {
      newForm = this.onChangeModelGeneration(newForm);
    } else if (form.getValue('model_style_id') !== newForm.getValue('model_style_id')) {
      newForm = this.onChangeModelStyle(newForm);
    } else if (form.getValue('model_variant_id') !== newForm.getValue('model_variant_id')) {
      newForm = this.onChangeModelVariant(newForm);
      const newModelVariantId = newForm.getValue('model_variant_id');
      if (newModelVariantId) {
        await this.loadModelVariant(Number(newModelVariantId));
      }
    }
    this.setState({ form: newForm });
  }

  async onSave() {
    const { car, close } = this.props;
    let { form } = this.state;
    form = form.trimValues();
    form = form.validateRequired('brand_id');
    form = form.validateRequired('model_id');
    form = form.validateRequired('model_generation_id');
    form = form.validateRequired('model_style_id');
    form = form.validateRequired('model_variant_id');
    form = form.validateRequired('model_color_id');
    form = form.validateRequired('price');
    form = form.validateRequired('vin');
    form = form.validateRequired('year');
    form = form.validateInteger('year', MIN_YEAR, new Date().getFullYear());
    form = form.validateMoney('price', true, 1);
    form = form.validateMoney('final_price', true, 1);
    if (form.hasError()) {
      this.setState({ form });
      alert(config.invalidFormMessage);
      return;
    }
    this.setState({ isLoading: true });
    try {
      const freshCar = await (car
        ? sdk.updateCar(car.id, this.getUpdateData(form))
        : sdk.createCar(this.getCreateData(form)));
      close(freshCar);
    } catch (err) {
      this.setState({ isLoading: false });
      handleError(err);
    }
  }

  // render helpers

  renderHeader() {
    const { close, car } = this.props;
    const title = car ? this.getCarTitle(car) : 'Новый автомобиль';
    return <ModalHeader title={title} close={close} />;
  }

  renderBody() {
    const { form } = this.state;
    return (
      <div className="modal-body">
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            name="brand_id"
            label="Марка"
            required
            disabled={this.isDisabled()}
            options={this.getBrandsOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="select"
            name="model_id"
            label="Модель"
            required
            disabled={this.isDisabled('brand_id')}
            options={this.getModelsOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="select"
            name="model_generation_id"
            label="Поколение"
            required
            disabled={this.isDisabled('model_id')}
            options={this.getModelGenerationsOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            name="model_style_id"
            label="Кузов"
            required
            disabled={this.isDisabled('model_generation_id')}
            options={this.getModelStylesOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="select"
            name="model_variant_id"
            label="Комплектация"
            required
            disabled={this.isDisabled('model_style_id')}
            options={this.getModelVariantsOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="select"
            name="model_color_id"
            label="Цвет"
            required
            disabled={this.isDisabled('model_variant_id')}
            options={this.getModelColorsOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-8"
            type="text"
            name="vin"
            label="VIN"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col-4"
            type="uint"
            name="year"
            label="Год"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="price"
            label="Цена, ₽"
            required
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="text"
            name="final_price"
            label="Спеццена, ₽"
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
          <FormGroup
            className="col"
            type="select"
            name="is_hidden"
            label="Статус"
            options={this.getStatusOptions()}
            form={form}
            onChange={(x) => this.onFormChange(x)}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    const { close } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="modal-footer">
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  initForm(car?: Car) {
    if (!car) {
      return createForm({});
    }
    return createForm({
      name: car.price,
      final_price: car.final_price !== car.price ? formatMoney(car.final_price) : null,
      price: formatMoney(car.price),
      year: car.year,
      vin: car.vin,
      model_color_id: car.model_color_id,
      model_variant_id: car.model_variant_id,
      model_style_id: car.model_style_id,
      model_generation_id: car.model_generation_id,
      model_id: car.model_id,
      brand_id: car.brand_id,
      is_hidden: String(car.is_hidden),
    });
  }

  getBrandsOptions(): Option[] {
    const { car } = this.props;
    const { brands = [] } = this.state;
    if (car) {
      return [{ value: String(car.brand_id), title: car.brand_name }];
    }
    return [NULL_OPTION_FEMALE].concat(
      brands.map((b: Brand) => ({
        value: String(b.id),
        title: b.name,
      })),
    );
  }

  getModelsOptions(): Option[] {
    const { car } = this.props;
    const { form } = this.state;
    if (car) {
      return [{ value: String(car.model_id), title: car.model_name }];
    }
    const brandId = form.getNumericValue('brand_id');
    if (!brandId) {
      return [NULL_OPTION_FEMALE];
    }
    const { models = [] } = this.state;
    return [NULL_OPTION_FEMALE].concat(
      models
        .filter((m) => m.brand_id === brandId)
        .map((m) => ({
          value: String(m.id),
          title: m.name,
        })),
    );
  }

  getModelGenerationsOptions(): Option[] {
    const { car } = this.props;
    const { form } = this.state;
    if (car) {
      return [{ value: String(car.model_generation_id), title: car.model_generation_name }];
    }
    const modelId = form.getNumericValue('model_id');
    if (!modelId) {
      return [NULL_OPTION];
    }
    const { modelGenerations = [] } = this.state;
    return [NULL_OPTION].concat(
      modelGenerations
        .filter((mg) => mg.model_id === modelId)
        .map((mg) => ({
          value: String(mg.id),
          title: mg.name,
        })),
    );
  }

  getModelStylesOptions(): Option[] {
    const { car } = this.props;
    const { form } = this.state;
    if (car) {
      return [{ value: String(car.model_style_id), title: car.model_style_name }];
    }
    const generationId = form.getNumericValue('model_generation_id');
    if (!generationId) {
      return [NULL_OPTION_MALE];
    }
    const { modelStyles = [] } = this.state;
    return [NULL_OPTION_MALE].concat(
      modelStyles
        .filter((ms) => ms.model_generation_id === generationId)
        .map((ms) => ({
          value: String(ms.id),
          title: ms.name,
        })),
    );
  }

  getModelVariantsOptions(): Option[] {
    const { car } = this.props;
    const { form } = this.state;
    if (car) {
      return [{ value: String(car.model_variant_id), title: car.model_variant_name }];
    }
    const modelStyleId = form.getNumericValue('model_style_id');
    if (!modelStyleId) {
      return [NULL_OPTION_FEMALE];
    }
    const { modelVariants = [] } = this.state;
    return [NULL_OPTION_FEMALE].concat(
      modelVariants
        .filter((mv) => mv.model_style_id === modelStyleId)
        .map((mv) => ({
          value: String(mv.id),
          title: this.getModelVariantTitle(mv),
        })),
    );
  }

  getModelColorsOptions(): Option[] {
    const { car } = this.props;
    const { modelVariant } = this.state;
    if (car) {
      return [{ value: String(car.model_color_id), title: car.model_color_name }];
    }
    if (!modelVariant || !modelVariant.colors) {
      return [NULL_OPTION_MALE];
    }
    return [NULL_OPTION_MALE].concat(
      modelVariant.colors.map((mc) => ({
        value: String(mc.id),
        title: mc.name,
      })),
    );
  }

  getStatusOptions() {
    return [
      {
        value: 'false',
        title: 'Активный',
      },
      {
        value: 'true',
        title: 'Скрыт',
      },
    ];
  }

  getCarTitle(car: Car) {
    return `${car.brand_name} ${car.model_name} ${car.year} г.`;
  }

  getModelVariantTitle(mv: ModelVariant) {
    const mvParams = [];
    if (mv.transmission_type) {
      mvParams.push(formatTransmissionTypeShort(mv.transmission_type));
    }
    if (mv.motor_volume) {
      mvParams.push(`${formatMotorVolumeInLitres(mv.motor_volume)} л`);
    }
    if (mv.motor_power) {
      mvParams.push(`${mv.motor_power} л.с.`);
    }
    if (mv.engine_type) {
      mvParams.push(formatEngineType(mv.engine_type));
    }
    return mvParams.length ? `${mv.name} – ${mvParams.join(', ')}` : mv.name;
  }

  getUpdateData(form: Form) {
    const pd = this.getPostData(form);
    return {
      price: pd.price,
      year: pd.year,
      final_price: pd.final_price,
      vin: pd.vin,
      is_hidden: pd.is_hidden,
    };
  }

  getCreateData(form: Form) {
    const { websiteId } = this.props;
    const pd = this.getPostData(form);
    return {
      website_id: websiteId,
      model_color_id: pd.model_color_id,
      price: pd.price,
      vin: pd.vin,
      year: pd.year,
      final_price: pd.final_price,
      is_hidden: pd.is_hidden,
    };
  }

  getPostData(form: Form) {
    const isHidden = form.getValue('is_hidden') === 'true';
    const price = form.getMoneyValue('price') || 0;
    return {
      model_color_id: form.getNumericValue('model_color_id') || 0,
      price: price,
      year: form.getNumericValue('year') || 0,
      vin: form.getValue('vin'),
      final_price: form.getMoneyValue('final_price') || price,
      is_hidden: isHidden,
    };
  }

  async loadData() {
    if (this.props.car) {
      return;
    }
    const brands = await sdk.getBrands();
    const models = await sdk.getModels();
    const modelGenerations = await sdk.getModelGenerations();
    const modelStyles = await sdk.getModelStyles();
    const modelVariants = await sdk.getModelVariants();
    saveListToCache(CACHE_BRANDS, brands);
    saveListToCache(CACHE_MODELS, models);
    saveListToCache(CACHE_MODEL_GENERATIONS, modelGenerations);
    saveListToCache(CACHE_MODEL_STYLES, modelStyles);
    saveListToCache(CACHE_MODEL_VARIANTS, modelVariants);
    this.setState({ brands, models, modelGenerations, modelStyles, modelVariants });
  }

  async loadModelVariant(modelVariantId: number) {
    try {
      const modelVariant = await sdk.getModelVariant(modelVariantId);
      this.setState({ modelVariant });
    } catch (e) {
      handleError(e);
    }
  }

  isDisabled(checkField?: string) {
    const { car } = this.props;
    const { form } = this.state;
    return Boolean(car || (checkField && !form.getValue(checkField)));
  }
}
