import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import { Company, Ticket, TicketComment, TicketCommentMedia } from '../../sdk';
import * as sdk from '../../sdk';
import { CACHE_COMPANY, loadItemFromCache } from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { CompanyTab, getCompanyCrumbs, getCompanyTabs, getTicketCrumbs } from '../../services/navigation.service';
import { Breadcrumb } from '../Breadcrumb';
import { TicketCommentForm } from '../TicketCommentForm';
import { TicketCommentItem } from '../TicketCommentItem';
import { Tabs } from '../Tabs';

interface P {
  match: match<{ companyId?: string; ticketId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  company?: Company;
  ticket: Ticket | null;
}

export class PTicketBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      company: this.getCachedCompany(),
      ticket: null,
    };
  }

  render() {
    return (
      <div className="PTicket">
        {this.renderBreadcrumb()}
        {this.renderTabs()}
        {this.renderBody()}
      </div>
    );
  }

  async componentDidMount() {
    await this.refreshData();
  }

  // event handlers

  async onAdd() {
    await this.refreshData();
  }

  async onDeleteComment(comment: TicketComment) {
    if (!window.confirm('Удалить комментарий?')) {
      return;
    }
    try {
      await sdk.deleteTicketComment(comment.id);
      await this.refreshData();
    } catch (err) {
      handleError(err);
    }
  }

  // render helpers

  renderBreadcrumb() {
    const { company } = this.state;
    if (!this.getCompanyId()) {
      const ticketId = this.getTicketId();
      return <Breadcrumb items={getTicketCrumbs(ticketId)} />;
    }
    return <Breadcrumb items={getCompanyCrumbs(company)} />;
  }

  renderTabs() {
    const companyId = this.getCompanyId();
    const ticketId = this.getTicketId();
    if (!companyId) {
      return null;
    }
    const items = [
      ...getCompanyTabs(companyId, CompanyTab.ticket),
      {
        title: `# ${ticketId}`,
        url: `/companies/${companyId}/tickets/${ticketId}`,
        active: true,
      },
    ];
    return <Tabs items={items} />;
  }

  renderComments() {
    const { ticket } = this.state;
    if (!ticket || !ticket.comments?.length) {
      return null;
    }
    return ticket.comments.map((c) => (
      <TicketCommentItem
        key={c.id}
        comment={c}
        onDelete={(comment) => this.onDeleteComment(comment)}
        attachments={this.getAttachmentsByCommentId(c.id)}
      />
    ));
  }

  renderBody() {
    const { ticket } = this.state;
    if (!ticket) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="d-flex mb-2 justify-content-between align-items-center">
          <h3>{ticket.title}</h3>
        </div>
        {this.renderComments()}
        <TicketCommentForm ticket={ticket} onUpdate={() => this.refreshData()} />
      </React.Fragment>
    );
  }

  // other helpers

  getCompanyId() {
    const { match } = this.props;
    return Number(match.params.companyId) || undefined;
  }

  getTicketId() {
    const { match } = this.props;
    return Number(match.params.ticketId);
  }

  getCachedCompany() {
    const companyId = this.getCompanyId();
    if (!companyId) {
      return undefined;
    }
    const company = loadItemFromCache<Company>(CACHE_COMPANY);
    if (!company) {
      return undefined;
    }
    if (company.id !== companyId) {
      return undefined;
    }
    return company;
  }

  async refreshData() {
    const companyId = this.getCompanyId();
    const ticketId = this.getTicketId();
    try {
      const company = companyId ? await sdk.getCompany(companyId) : undefined;
      const ticket = await sdk.getTicket(ticketId);
      this.setState({ ticket, company });
    } catch (e) {
      handleError(e);
    }
  }

  getAttachmentsByCommentId(commentId: number): TicketCommentMedia[] {
    const { ticket } = this.state;
    if (!ticket || !ticket.attachments) {
      return [];
    }
    return ticket?.attachments.filter((a) => a.ticket_comment_id === commentId);
  }
}

export const PTicket = withRouter(PTicketBase);
