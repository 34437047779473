import { IconName } from '@fortawesome/fontawesome-svg-core';
import * as H from 'history';
import React from 'react';
import { match, withRouter } from 'react-router-dom';

import * as sdk from '../../sdk';
import { ModelStyle, ModelVariant } from '../../sdk';
import {
  CACHE_MODEL_STYLE,
  CACHE_MODEL_VARIANT,
  CACHE_MODEL_VARIANTS,
  loadItemFromCache,
  loadListFromCache,
  saveItemToCache,
  saveListToCache,
} from '../../services/cache.service';
import { handleError } from '../../services/error.service';
import { formatMoney, formatMotorVolumeInLitres, formatTransmissionTypeShort } from '../../services/fmt.service';
import { showModal } from '../../services/modal.service';
import { goTo } from '../../services/router.service';
import { Breadcrumb } from '../Breadcrumb';
import { Button } from '../Button';
import { Column, List, MenuItem } from '../List';
import { MModelVariant } from '../modals/MModelVariant';
import { MPrompt } from '../modals/MPrompt';

interface P {
  match: match<{ brandId: string; modelId: string; generationId: string; styleId: string }>;
  history: H.History;
  location: H.Location;
}

interface S {
  modelStyle?: ModelStyle;
  items: ModelVariant[];
  search: string;
  copyImagesFromId?: number;
}

export class PModelVariantsBase extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      modelStyle: this.getCachedModelStyle(),
      items: this.getCachedModelVariants(),
      search: '',
    };
  }

  render() {
    const { items, search } = this.state;
    return (
      <div className="PModelVariants">
        {this.renderBreadcrumb()}
        <div className="d-flex mb-3">
          <Button type="primary" text="Добавить" onClick={() => this.onAdd()} />
          <input
            className="form-control ml-3"
            type="text"
            placeholder="Поиск"
            value={search}
            onChange={(e) => this.onSearchChange(e)}
          />
        </div>
        <List
          items={items}
          columns={this.getColumns()}
          search={search}
          buttons={this.getMoveButtons()}
          onGetItemClassName={(x) => this.onGetItemClassName(x)}
          onGetItemMenu={(x) => this.onGetItemMenu(x)}
          onItemSelect={(x) => this.onItemSelect(x)}
        />
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  // event handlers

  async onAdd() {
    const { modelStyle } = this.state;
    await showModal<string>(MModelVariant, { modelStyle });
    await this.loadData();
  }

  onSearchChange(e: any) {
    this.setState({ search: e.target.value });
  }

  onGetItemClassName(item: ModelVariant) {
    const { copyImagesFromId } = this.state;
    if (item.id === copyImagesFromId) {
      return 'table-primary';
    }
    return '';
  }

  onGetItemMenu(item: ModelVariant) {
    const { copyImagesFromId } = this.state;
    const menuItems: MenuItem[] = [];
    menuItems.push({
      name: 'Редактировать',
      action: async () => {
        const { modelStyle } = this.state;
        await showModal<string>(MModelVariant, {
          modelStyle,
          modelVariant: item,
        });
        await this.loadData();
      },
    });
    menuItems.push({
      name: 'Копировать',
      action: async () => {
        const name = await showModal<string>(MPrompt, {
          label: 'Имя',
          title: 'Копирование комплектации',
          action: 'Сохранить',
          defaultValue: `${item.name} (копия)`,
          isRequired: true,
        });
        if (name) {
          try {
            const modelVariant = await sdk.copyModelVariant(item.id, { name });
            await this.runPrimaryAction(modelVariant);
          } catch (err) {
            handleError(err);
          }
        }
      },
    });
    menuItems.push({
      name: 'Копировать фото',
      action: async () => {
        this.setState({
          copyImagesFromId: copyImagesFromId === item.id ? undefined : item.id,
        });
      },
    });
    if (copyImagesFromId && copyImagesFromId !== item.id) {
      menuItems.push({
        name: 'Вставить фото',
        action: async () => {
          try {
            const count = await sdk.copyModelVariantImages(copyImagesFromId, item.id);
            alert(`Скопировано изображений: ${count}`);
          } catch (err) {
            handleError(err);
          }
        },
      });
    }
    menuItems.push({
      name: 'Удалить',
      action: async () => {
        const msg = `Удалить комплектацию "${item.name}"?`;
        if (!window.confirm(msg)) {
          return;
        }
        try {
          await sdk.deleteModelVariant(item.id);
          await this.loadData();
        } catch (err) {
          handleError(err);
        }
      },
    });
    return menuItems;
  }

  async onItemSelect(item: ModelVariant) {
    await this.runPrimaryAction(item);
  }

  // render helpers

  renderBreadcrumb() {
    const { modelStyle } = this.state;
    const crumbs = modelStyle
      ? [
          { title: 'Каталог', url: '/catalog' },
          { title: modelStyle.brand_name, url: `/catalog/${modelStyle.brand_id}` },
          { title: modelStyle.model_name, url: `/catalog/${modelStyle.brand_id}/${modelStyle.model_id}` },
          {
            title: modelStyle.model_generation_name,
            url: `/catalog/${modelStyle.brand_id}/${modelStyle.model_id}/${modelStyle.model_generation_id}`,
          },
          { title: modelStyle.name },
        ]
      : [];
    return <Breadcrumb items={crumbs} />;
  }

  // other helpers

  getModelId() {
    const { match } = this.props;
    return Number(match.params.modelId);
  }

  getModelStyleId() {
    const { match } = this.props;
    return Number(match.params.styleId);
  }

  getCachedModelStyle() {
    const modelStyleId = this.getModelStyleId();
    const modelStyle = loadItemFromCache<ModelStyle>(CACHE_MODEL_STYLE);
    if (!modelStyle || modelStyle.id !== modelStyleId) {
      return undefined;
    }
    return modelStyle;
  }

  getCachedModelVariants() {
    const styleId = this.getModelStyleId();
    const allItems = loadListFromCache<ModelVariant>(CACHE_MODEL_VARIANTS);
    return allItems.filter((item) => item.model_style_id === styleId);
  }

  getColumns(): Column<ModelVariant>[] {
    return [
      {
        name: 'Имя',
        value: (item) => item.name,
      },
      {
        name: 'Код',
        value: (item) => item.code,
      },
      {
        name: 'КП',
        value: (item) => item.transmission_type && formatTransmissionTypeShort(item.transmission_type),
        headClassName: 'w-60px',
      },
      {
        name: 'Двигатель',
        value: (item) => (item.motor_volume ? `${formatMotorVolumeInLitres(item.motor_volume)} л` : null),
        headClassName: 'w-120px',
      },
      {
        name: 'Мощность',
        value: (item) => (item.motor_power ? `${item.motor_power} л.с.` : null),
        headClassName: 'w-120px',
      },
      {
        name: 'Базовая цена',
        value: (item) => formatMoney(item.price, false, true),
        headClassName: 'w-140px',
      },
    ];
  }

  async loadData() {
    const styleId = this.getModelStyleId();
    try {
      const [allItems, modelStyle] = await Promise.all([sdk.getModelVariants(), sdk.getModelStyle(styleId)]);
      saveListToCache(CACHE_MODEL_VARIANTS, allItems);
      const items = allItems.filter((item) => item.model_style_id === modelStyle.id);
      this.setState({ items, modelStyle });
    } catch (e) {
      handleError(e);
    }
  }

  async runPrimaryAction(modelVariant: ModelVariant) {
    saveItemToCache(CACHE_MODEL_VARIANT, modelVariant);
    goTo(
      `/catalog/${modelVariant.brand_id}/${modelVariant.model_id}/${modelVariant.model_generation_id}/${modelVariant.model_style_id}/${modelVariant.id}`,
    );
  }

  getMoveButtons() {
    return [
      {
        icon: 'arrow-up' as IconName,
        action: async (modelVariant: ModelVariant) => {
          try {
            await sdk.moveModelVariantUp(modelVariant.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
      {
        icon: 'arrow-down' as IconName,
        action: async (modelVariant: ModelVariant) => {
          try {
            await sdk.moveModelVariantDown(modelVariant.id);
            await this.loadData();
          } catch (err) {
            handleError(err);
          }
        },
      },
    ];
  }
}

export const PModelVariants = withRouter(PModelVariantsBase);
