import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { cn } from '../lib/utils.lib';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'link'
  | 'outline-secondary';

interface P {
  type: ButtonType;
  text?: string;
  icon?: IconProp;
  size?: 'lg' | 'sm';
  className?: string;
  disabled?: boolean;
  dataDismiss?: string;
  dataToggle?: string;
  onClick?: () => void;
}

export class Button extends React.Component<P> {
  render() {
    const { type, size, text, className, disabled, dataDismiss, dataToggle } = this.props;
    return (
      <button
        className={cn(className, `btn btn-${type}`, size && `btn-${size}`)}
        type="button"
        disabled={disabled}
        data-dismiss={dataDismiss}
        data-toggle={dataToggle}
        onClick={() => this.onClick()}
      >
        {text}
        {this.renderIcon()}
      </button>
    );
  }

  // event handlers

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  // render helpers

  renderIcon() {
    const { icon } = this.props;
    if (!icon) {
      return null;
    }
    return <FontAwesomeIcon icon={icon} />;
  }
}
