import { config } from '../config';

interface Record {
  [key: string]: any;
}

let token: string | undefined;

export function setToken(value: string | undefined) {
  token = value;
}

export function get(path: string, params?: Record) {
  return sendRequest({ path, method: 'GET', query: params });
}

export function post(path: string, params?: Record) {
  return sendRequest({ path, method: 'POST', data: params });
}

export function put(path: string, params?: Record) {
  return sendRequest({ path, method: 'PUT', data: params });
}

export function patch(path: string, params?: Record) {
  return sendRequest({ path, method: 'PATCH', data: params });
}

export function del(path: string, params?: Record) {
  return sendRequest({ path, method: 'DELETE', query: params });
}

export function upload(path: string, file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return sendRequest({ path, method: 'POST', formData });
}

// private

interface RequestOptions {
  method: string;
  path: string;
  query?: Record;
  data?: Record;
  formData?: FormData;
}

async function sendRequest(options: RequestOptions): Promise<Record> {
  const url = getUrl(options.path, options.query);
  const init: any = { method: options.method, headers: {} };
  if (token) {
    init.headers['X-Token'] = token;
  }
  if (options.data) {
    init.headers['Content-Type'] = 'application/json';
    init.body = JSON.stringify(options.data);
  }
  if (options.formData) {
    init.body = options.formData;
  }
  const response = await fetch(url, init);
  return processResponse(response);
}

function getUrl(path: string, query?: Record) {
  const url = config.api + path;
  if (!query) {
    return url;
  }
  const pairs = Object.keys(query)
    .filter((key) => query[key] != null)
    .map((key) => `${key}=${encodeURIComponent(query[key])}`);
  if (pairs.length === 0) {
    return url;
  }
  const queryPart = pairs.join('&');
  return `${url}?${queryPart}`;
}

async function processResponse(response: Response): Promise<Record> {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    const error = Error(`API Error ${response.status}`);
    (error as any).status = response.status;
    (error as any).data = data;
    throw error;
  }
  return data;
}

async function getResponseData(response: Response): Promise<Record> {
  const text = await response.text();
  if (!text) {
    return {};
  }
  const first = text.charAt(0);
  if (first !== '{') {
    return { text };
  }
  return JSON.parse(text);
}
